var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var IL,KL,ML,PL,UL;$CLJS.DL=function(a){return $CLJS.Wh($CLJS.q($CLJS.mC),a,$CLJS.vG)};$CLJS.EL=new $CLJS.M(null,"many-pks?","many-pks?",-459675021);$CLJS.FL=new $CLJS.M(null,"row","row",-570139521);$CLJS.GL=new $CLJS.M(null,"column-ref","column-ref",2018188376);$CLJS.HL=new $CLJS.M(null,"object-id","object-id",-754527291);IL=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);
$CLJS.JL=new $CLJS.M(null,"operators","operators",-2064102509);KL=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.LL=new $CLJS.M(null,"stage-number","stage-number",-1752729638);ML=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.NL=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.OL=new $CLJS.M(null,"dimensions","dimensions",-254818097);
PL=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.QL=new $CLJS.M("drill-thru","pk","drill-thru/pk",1721977789);$CLJS.RL=new $CLJS.M("drill-thru","fk-details","drill-thru/fk-details",-687193600);$CLJS.SL=new $CLJS.M(null,"max-value","max-value",687805168);$CLJS.TL=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);
UL=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.VL=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.X(KL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,$CLJS.GE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xG,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),$CLJS.oE],null)],null)],null));$CLJS.X(UL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,KL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xB,$CLJS.KE],null)],null)],null));$CLJS.X(ML,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zr,$CLJS.rG],null),UL,$CLJS.BE],null));
$CLJS.X(PL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zr,$CLJS.rG],null),KL,$CLJS.mL],null));
$CLJS.RG.g($CLJS.rG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zr,$CLJS.rG],null),KL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ws,$CLJS.mL,$CLJS.BE],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.h(null,2,[$CLJS.Ni,function(a){return $CLJS.wd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.kC(a)):null},$CLJS.wt,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.oB,PL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nB,ML],null)],null)],null));$CLJS.zF($CLJS.rG,$CLJS.vG);$CLJS.VE.m(null,$CLJS.rG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.FA($CLJS.Ei,$CLJS.xB)(b);return $CLJS.n(a)?a:$CLJS.hF});$CLJS.GF($CLJS.uB,$CLJS.H([$CLJS.BE]));$CLJS.VE.m(null,$CLJS.uB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.FA($CLJS.Ei,$CLJS.xB)(b);return $CLJS.n(a)?a:$CLJS.hF});
$CLJS.zF($CLJS.uB,$CLJS.vG);$CLJS.X(IL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,$CLJS.GE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),$CLJS.BE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wE,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),$CLJS.BE],null)],null)],null));$CLJS.RG.g($CLJS.gG,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zr,$CLJS.gG],null),IL,$CLJS.Xj],null));
$CLJS.VE.m(null,$CLJS.gG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.FA($CLJS.Ei,$CLJS.xB)(b);return $CLJS.n(a)?a:$CLJS.hF});$CLJS.zF($CLJS.gG,$CLJS.vG);$CLJS.GF($CLJS.QF,$CLJS.H([$CLJS.pt,$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WK],null)],null)]));$CLJS.zF($CLJS.QF,$CLJS.vG);
$CLJS.GF($CLJS.mG,$CLJS.H([$CLJS.pt,$CLJS.hF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UK],null)],null)]));$CLJS.zF($CLJS.mG,$CLJS.vG);
$CLJS.X($CLJS.vG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ds,$CLJS.XE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.yt,function(){return["Valid reference, must be one of these clauses: ",$CLJS.Ht(", ",$CLJS.cH($CLJS.Jd,$CLJS.il.g($CLJS.q($CLJS.mC),$CLJS.vG)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.DL(a)}],null)],null));