var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.core.protocols.js");require("./clojure.string.js");require("./clojure.walk.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.util.js");require("./metabase.util.js");require("./metabase.util.log.js");
'use strict';var g9,i9,h9,Lva,o9,Mva,p9,Nva,Ova,Pva,Qva,j9,Rva,u9,Sva,Tva,Uva;g9=function(a,b){return $CLJS.n($CLJS.n(a)?b in a:a)?$CLJS.Ca(a,b):null};i9=function(a,b){return h9(a,b,$CLJS.N)};
h9=function(a,b,c){c=$CLJS.O(c);c=$CLJS.J.j(c,j9,!0);if($CLJS.xd(b))return $CLJS.eg.j($CLJS.N,a,b);c=$CLJS.n(c)?function(){var d=g9(b,"_plainObject");d=null==d?null:$CLJS.Tz(d);return null==d?null:$CLJS.Fe(d)}():null;return $CLJS.n(c)?$CLJS.eg.j($CLJS.N,a,c):$CLJS.eg.j($CLJS.N,$CLJS.Nk.j($CLJS.hf.h(function(d){return new $CLJS.P(null,2,5,$CLJS.Q,[d,g9(b,d)],null)}),$CLJS.Ok.h(function(d){$CLJS.I(d,0,null);return"function"===typeof $CLJS.I(d,1,null)}),a),$CLJS.Ba(b))};
Lva=function(a){var b=k9.h(a),c=l9.h(a),d=m9.h(a);return $CLJS.Nk.j($CLJS.hf.h(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);var k=$CLJS.Q;f=$CLJS.zh.h($CLJS.hB.h?$CLJS.hB.h(f):$CLJS.hB.call(null,f));if($CLJS.n(d)){var l=d.h?d.h(f):d.call(null,f);f=$CLJS.n(l)?l:f}return new $CLJS.P(null,2,5,k,[f,e],null)}),$CLJS.td(b)?$CLJS.Td:$CLJS.Ok.h(function(e){var f=$CLJS.I(e,0,null);$CLJS.I(e,1,null);return $CLJS.Hd(b,f)}),$CLJS.Va(c)?$CLJS.Td:$CLJS.hf.h(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,
1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[f,c.g?c.g(f,e):c.call(null,f,e)],null)}))};
o9=function(a,b){var c=Lva(a),d=n9.h(a);return function(e){try{var f=$CLJS.R.j(h9(c,e,b),$CLJS.du,d),k=$CLJS.PA($CLJS.Hy);$CLJS.n($CLJS.OA("metabase.lib.js.metadata",k))&&$CLJS.NA("metabase.lib.js.metadata",k,$CLJS.DW("Parsed metadata %s %s\n%s",$CLJS.H([a,$CLJS.Yi.h(f),$CLJS.EW(f)])),null);return f}catch(l){if(l instanceof Error)return f=l,k=$CLJS.PA($CLJS.Iy),$CLJS.n($CLJS.OA("metabase.lib.js.metadata",k))&&(f instanceof Error?$CLJS.NA("metabase.lib.js.metadata",k,$CLJS.DW("Error parsing %s %s: %s",
$CLJS.H([a,$CLJS.Mh.l($CLJS.H([e])),$CLJS.BV(f)])),f):$CLJS.NA("metabase.lib.js.metadata",k,$CLJS.DW(f,$CLJS.H(["Error parsing %s %s: %s",a,$CLJS.Mh.l($CLJS.H([e])),$CLJS.BV(f)])),null)),null;throw l;}}};Mva=function(a){return i9($CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=$CLJS.zh.h($CLJS.hB.h?$CLJS.hB.h(c):$CLJS.hB.call(null,c));c=$CLJS.E.g(c,$CLJS.LV)?$CLJS.ME:c;b=$CLJS.E.g(c,$CLJS.ME)?$CLJS.zh.h(b):b;return new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null)}),a)};
p9=function(a){return $CLJS.Pk.g(o9($CLJS.rG,$CLJS.N),a)};Nva=new $CLJS.M(null,"plain-object","plain-object",-943264281);$CLJS.q9=new $CLJS.M(null,"cards","cards",169174038);Ova=new $CLJS.M(null,"binning-info","binning-info",-2117036653);Pva=new $CLJS.M(null,"db","db",993250759);$CLJS.r9=new $CLJS.M(null,"metrics","metrics",394093469);Qva=new $CLJS.M(null,"fks","fks",2041987076);$CLJS.s9=new $CLJS.M(null,"segments","segments",1937535949);
j9=new $CLJS.M(null,"use-plain-object?","use-plain-object?",-1174595475);Rva=new $CLJS.M(null,"metadata","metadata",1799301597);$CLJS.t9=new $CLJS.M(null,"tables","tables",1334623052);u9=new $CLJS.M(null,"dimension-options","dimension-options",2122379680);Sva=new $CLJS.M(null,"schema-name","schema-name",1666725119);Tva=new $CLJS.M(null,"_comesFromEndpoint","_comesFromEndpoint",-1390455588);Uva=new $CLJS.M(null,"default-dimension-option","default-dimension-option",149055958);
$CLJS.v9=new $CLJS.M(null,"databases","databases",2040134125);var k9,Vva=$CLJS.Xe($CLJS.N),Wva=$CLJS.Xe($CLJS.N),Xva=$CLJS.Xe($CLJS.N),Yva=$CLJS.Xe($CLJS.N),Zva=$CLJS.J.j($CLJS.N,$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null)),x9,owa,pwa,qwa,rwa,swa;k9=new $CLJS.di($CLJS.Fh.g("metabase.lib.js.metadata","excluded-keys"),$CLJS.zh,Zva,Vva,Wva,Xva,Yva);k9.m(null,$CLJS.ci,function(){return null});var l9,$va=$CLJS.Xe($CLJS.N),awa=$CLJS.Xe($CLJS.N),bwa=$CLJS.Xe($CLJS.N),cwa=$CLJS.Xe($CLJS.N),dwa=$CLJS.J.j($CLJS.N,$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));
l9=new $CLJS.di($CLJS.Fh.g("metabase.lib.js.metadata","parse-field-fn"),$CLJS.zh,dwa,$va,awa,bwa,cwa);l9.m(null,$CLJS.ci,function(){return null});var n9,ewa=$CLJS.Xe($CLJS.N),fwa=$CLJS.Xe($CLJS.N),gwa=$CLJS.Xe($CLJS.N),hwa=$CLJS.Xe($CLJS.N),iwa=$CLJS.J.j($CLJS.N,$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));n9=new $CLJS.di($CLJS.Fh.g("metabase.lib.js.metadata","lib-type"),$CLJS.zh,iwa,ewa,fwa,gwa,hwa);
var m9,jwa=$CLJS.Xe($CLJS.N),kwa=$CLJS.Xe($CLJS.N),lwa=$CLJS.Xe($CLJS.N),mwa=$CLJS.Xe($CLJS.N),nwa=$CLJS.J.j($CLJS.N,$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));m9=new $CLJS.di($CLJS.Fh.g("metabase.lib.js.metadata","rename-key-fn"),$CLJS.Td,nwa,jwa,kwa,lwa,mwa);m9.m(null,$CLJS.ci,function(){return null});
$CLJS.w9=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.js.metadata","parse-objects"),function(f){return $CLJS.zh.h(f)},e,a,b,c,d)}();owa=$CLJS.Xe($CLJS.N);pwa=$CLJS.Xe($CLJS.N);qwa=$CLJS.Xe($CLJS.N);rwa=$CLJS.Xe($CLJS.N);swa=$CLJS.J.j($CLJS.N,$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));
x9=new $CLJS.di($CLJS.Fh.g("metabase.lib.js.metadata","parse-objects-default-key"),$CLJS.zh,swa,owa,pwa,qwa,rwa);$CLJS.w9.m(null,$CLJS.ci,function(a,b){var c=o9(a,$CLJS.N);return i9($CLJS.hf.h(function(d){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CW(e),new $CLJS.Jh(function(){return c.h?c.h(f):c.call(null,f)})],null)}),g9(b,x9.h(a)))});n9.m(null,$CLJS.cQ,function(){return $CLJS.kL});
k9.m(null,$CLJS.cQ,function(){return new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.aF,null,$CLJS.t9,null],null),null)});l9.m(null,$CLJS.cQ,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "dbms-version":return $CLJS.Sz(b,$CLJS.H([$CLJS.yi,!0]));case "features":return $CLJS.eg.j($CLJS.bh,$CLJS.hf.h($CLJS.zh),b);case "native-permissions":return $CLJS.zh.h(b);default:return b}}});x9.m(null,$CLJS.cQ,function(){return"databases"});n9.m(null,$CLJS.XW,function(){return $CLJS.$K});
k9.m(null,$CLJS.XW,function(){return new $CLJS.ah(null,new $CLJS.h(null,5,[u9,null,$CLJS.aF,null,$CLJS.s9,null,$CLJS.cQ,null,$CLJS.r9,null],null),null)});l9.m(null,$CLJS.XW,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "entity-type":return $CLJS.zh.h(b);case "field-order":return $CLJS.zh.h(b);case "initial-sync-status":return $CLJS.zh.h(b);case "visibility-type":return $CLJS.zh.h(b);default:return b}}});
$CLJS.w9.m(null,$CLJS.XW,function(a,b){var c=o9(a,$CLJS.N);return i9($CLJS.Nk.g($CLJS.Ok.h(function(d){var e=$CLJS.I(d,0,null);$CLJS.I(d,1,null);return 0==e.lastIndexOf("card__",0)}),$CLJS.hf.h(function(d){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CW(e),new $CLJS.Jh(function(){return c.h?c.h(f):c.call(null,f)})],null)})),g9(b,"tables"))});n9.m(null,$CLJS.rG,function(){return $CLJS.TK});
k9.m(null,$CLJS.rG,function(){return new $CLJS.ah(null,new $CLJS.h(null,7,[u9,null,$CLJS.XW,null,$CLJS.OL,null,Uva,null,$CLJS.cQ,null,Tva,null,$CLJS.r9,null],null),null)});m9.m(null,$CLJS.rG,function(){return new $CLJS.h(null,4,[$CLJS.fA,$CLJS.VK,$CLJS.ri,$CLJS.SW,$CLJS.jS,$CLJS.AE,Ova,$CLJS.YW],null)});
l9.m(null,$CLJS.rG,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "base-type":return $CLJS.zh.h(b);case "coercion-strategy":return $CLJS.zh.h(b);case "effective-type":return $CLJS.zh.h(b);case "fingerprint":return $CLJS.xd(b)?$CLJS.EV(b):$CLJS.Sz(b,$CLJS.H([$CLJS.yi,!0]));case "has-field-values":return $CLJS.zh.h(b);case "lib/source":switch(b){case "aggregation":return $CLJS.oL;case "breakout":return $CLJS.iL;default:return $CLJS.zh.g("source",b)}case "metabase.lib.field/temporal-unit":return $CLJS.zh.h(b);
case "semantic-type":return $CLJS.zh.h(b);case "visibility-type":return $CLJS.zh.h(b);case "id":return $CLJS.zd(b)?$CLJS.hd(b):b;case "metabase.lib.field/binning":return Mva(b);default:return b}}});
$CLJS.w9.m(null,$CLJS.rG,function(a,b){var c=o9(a,$CLJS.N),d=g9(b,"fields");return i9($CLJS.Vl(function(e){var f=$CLJS.I(e,0,null),k=$CLJS.I(e,1,null);e=function(){var l=$CLJS.CW(f);if($CLJS.n(l))return l;l=$CLJS.nh(/card__\d+:(\d+)/,f);if($CLJS.n(l)){$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);var m=null==g9(d,l);return m?$CLJS.CW(l):m}return null}();return $CLJS.n(e)?new $CLJS.P(null,2,5,$CLJS.Q,[e,new $CLJS.Jh(function(){return c.h?c.h(k):c.call(null,k)})],null):null}),d)});n9.m(null,$CLJS.EN,function(){return $CLJS.nL});
k9.m(null,$CLJS.EN,function(){return new $CLJS.ah(null,new $CLJS.h(null,11,[u9,null,$CLJS.ol,null,Qva,null,$CLJS.XW,null,Pva,null,Nva,null,$CLJS.s9,null,$CLJS.cQ,null,Rva,null,$CLJS.r9,null,Sva,null],null),null)});
l9.m(null,$CLJS.EN,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "result-metadata":return $CLJS.n($CLJS.FA($CLJS.wd,$CLJS.Ua)(b))?p9(b):$CLJS.Sz(b,$CLJS.H([$CLJS.yi,!0]));case "fields":return p9(b);case "visibility-type":return $CLJS.zh.h(b);case "dataset-query":return $CLJS.Sz(b,$CLJS.H([$CLJS.yi,!0]));case "dataset":return b;default:return b}}});
$CLJS.w9.m(null,$CLJS.EN,function(a,b){return $CLJS.eg.j($CLJS.N,$CLJS.hf.h(function(c){return new $CLJS.P(null,2,5,$CLJS.Q,[c,new $CLJS.Jh(function(){var d=o9($CLJS.EN,new $CLJS.h(null,1,[j9,!1],null)),e=o9($CLJS.EN,$CLJS.N),f=$CLJS.el.l;d=$CLJS.R.j(d(g9(g9(b,"tables"),["card__",$CLJS.p.h(c)].join(""))),$CLJS.Yi,c);var k=g9(g9(b,"questions"),$CLJS.p.h(c));var l=g9(k,"_card");k=$CLJS.n(l)?l:k;e=e.h?e.h(k):e.call(null,k);return f.call($CLJS.el,$CLJS.H([d,e]))})],null)}),$CLJS.eg.j($CLJS.eg.j($CLJS.bh,
$CLJS.Vl($CLJS.PW),$CLJS.Ba(g9(b,"tables"))),$CLJS.hf.h($CLJS.CW),$CLJS.Ba(g9(b,"questions"))))});n9.m(null,$CLJS.mG,function(){return $CLJS.ZK});k9.m(null,$CLJS.mG,function(){return new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.XW,null,$CLJS.cQ,null],null),null)});l9.m(null,$CLJS.mG,function(){return function(a,b){return b}});x9.m(null,$CLJS.mG,function(){return"metrics"});n9.m(null,$CLJS.QF,function(){return $CLJS.XK});
k9.m(null,$CLJS.QF,function(){return new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.XW,null,$CLJS.cQ,null],null),null)});l9.m(null,$CLJS.QF,function(){return function(a,b){return b}});x9.m(null,$CLJS.QF,function(){return"segments"});$CLJS.y9=o9($CLJS.rG,$CLJS.N);