var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.types.isa.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var v2=function(a,b){a=a instanceof $CLJS.M?a.T:null;switch(a){case "\x3d":switch(b=b instanceof $CLJS.M?b.T:null,b){case "equal-to":return $CLJS.TE("Equal to");case "default":return $CLJS.TE("Is");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "!\x3d":switch(b=b instanceof $CLJS.M?b.T:null,b){case "not-equal-to":return $CLJS.TE("Not equal to");case "excludes":return $CLJS.TE("Excludes");case "default":return $CLJS.TE("Is not");default:throw Error(["No matching clause: ",
$CLJS.p.h(b)].join(""));}case "\x3e":switch(b=b instanceof $CLJS.M?b.T:null,b){case "after":return $CLJS.TE("After");case "default":return $CLJS.TE("Greater than");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "\x3c":switch(b=b instanceof $CLJS.M?b.T:null,b){case "before":return $CLJS.TE("Before");case "default":return $CLJS.TE("Less than");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "\x3e\x3d":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.TE("Greater than or equal to");
default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "\x3c\x3d":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.TE("Less than or equal to");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "between":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.TE("Between");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "is-null":switch(b=b instanceof $CLJS.M?b.T:null,b){case "is-empty":return $CLJS.TE("Is empty");
case "default":return $CLJS.TE("Is null");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "not-null":switch(b=b instanceof $CLJS.M?b.T:null,b){case "not-empty":return $CLJS.TE("Not empty");case "default":return $CLJS.TE("Not null");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "is-empty":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.TE("Is empty");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "not-empty":switch(b=
b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.TE("Not empty");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "contains":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.TE("Contains");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "does-not-contain":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.TE("Does not contain");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));
}case "starts-with":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.TE("Starts with");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "ends-with":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.TE("Ends with");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "inside":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.TE("Inside");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));
}default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}},w2=function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "\x3d":return"\x3d";case "!\x3d":return"≠";case "\x3e":return"\x3e";case "\x3c":return"\x3c";case "\x3e\x3d":return"≥";case "\x3c\x3d":return"≤";default:return v2(a,b)}},noa=new $CLJS.M(null,"after","after",594996914),ooa=new $CLJS.M(null,"equal-to","equal-to",608296653),poa=new $CLJS.M(null,"excludes","excludes",-1791725945),qoa=new $CLJS.M(null,"not-equal-to","not-equal-to",
-343181655),roa=new $CLJS.M(null,"before","before",-1633692388);$CLJS.x2=function(){function a(d,e){return new $CLJS.h(null,3,[$CLJS.du,$CLJS.YJ,$CLJS.kG,d,$CLJS.ZJ,e],null)}function b(d){return c.g?c.g(d,$CLJS.ci):c.call(null,d,$CLJS.ci)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.y2=new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.x2.h($CLJS.Zr),$CLJS.x2.h($CLJS.IG),$CLJS.x2.h($CLJS.Sr),$CLJS.x2.h($CLJS.Wr),$CLJS.x2.h($CLJS.UF),$CLJS.x2.h($CLJS.Ur),$CLJS.x2.h($CLJS.Yr),$CLJS.x2.g($CLJS.TF,$CLJS.VF),$CLJS.x2.g($CLJS.dG,$CLJS.bG)],null);$CLJS.soa=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.x2.h($CLJS.Zr),$CLJS.x2.h($CLJS.IG),$CLJS.x2.h($CLJS.VF),$CLJS.x2.h($CLJS.bG),$CLJS.x2.h($CLJS.qG),$CLJS.x2.h($CLJS.jG),$CLJS.x2.h($CLJS.iG),$CLJS.x2.h($CLJS.CG)],null);
$CLJS.toa=new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.x2.g($CLJS.IG,poa),$CLJS.x2.h($CLJS.Zr),$CLJS.x2.g($CLJS.Wr,roa),$CLJS.x2.g($CLJS.Sr,noa),$CLJS.x2.h($CLJS.UF),$CLJS.x2.g($CLJS.TF,$CLJS.VF),$CLJS.x2.g($CLJS.dG,$CLJS.bG)],null);$CLJS.uoa=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.x2.h($CLJS.Zr),$CLJS.x2.h($CLJS.IG),$CLJS.x2.h($CLJS.$F),$CLJS.x2.h($CLJS.Sr),$CLJS.x2.h($CLJS.Wr),$CLJS.x2.h($CLJS.UF),$CLJS.x2.h($CLJS.Ur),$CLJS.x2.h($CLJS.Yr)],null);
$CLJS.voa=new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.x2.g($CLJS.Zr,ooa),$CLJS.x2.g($CLJS.IG,qoa),$CLJS.x2.h($CLJS.Sr),$CLJS.x2.h($CLJS.Wr),$CLJS.x2.h($CLJS.UF),$CLJS.x2.h($CLJS.Ur),$CLJS.x2.h($CLJS.Yr),$CLJS.x2.g($CLJS.TF,$CLJS.VF),$CLJS.x2.g($CLJS.dG,$CLJS.bG)],null);
$CLJS.woa=new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.x2.h($CLJS.Zr),$CLJS.x2.h($CLJS.IG),$CLJS.x2.h($CLJS.qG),$CLJS.x2.h($CLJS.jG),$CLJS.x2.h($CLJS.TF),$CLJS.x2.h($CLJS.dG),$CLJS.x2.h($CLJS.VF),$CLJS.x2.h($CLJS.bG),$CLJS.x2.h($CLJS.iG),$CLJS.x2.h($CLJS.CG)],null);$CLJS.xoa=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.x2.h($CLJS.Zr),$CLJS.x2.h($CLJS.IG),$CLJS.x2.h($CLJS.TF),$CLJS.x2.h($CLJS.dG),$CLJS.x2.h($CLJS.VF),$CLJS.x2.h($CLJS.bG)],null);
$CLJS.yoa=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.x2.h($CLJS.Zr),$CLJS.x2.g($CLJS.TF,$CLJS.VF),$CLJS.x2.g($CLJS.dG,$CLJS.bG)],null);$CLJS.zoa=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.x2.h($CLJS.Zr),$CLJS.x2.h($CLJS.IG),$CLJS.x2.h($CLJS.TF),$CLJS.x2.h($CLJS.dG)],null);$CLJS.z2=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.R.j($CLJS.x2.h($CLJS.Zr),$CLJS.ci,!0),$CLJS.x2.h($CLJS.Sr),$CLJS.x2.h($CLJS.Wr),$CLJS.x2.h($CLJS.Ur),$CLJS.x2.h($CLJS.Yr),$CLJS.x2.h($CLJS.IG)],null);
$CLJS.J0.m(null,$CLJS.YJ,function(a,b,c,d){b=$CLJS.O(c);a=$CLJS.J.g(b,$CLJS.kG);b=$CLJS.J.g(b,$CLJS.ZJ);d=d instanceof $CLJS.M?d.T:null;switch(d){case "default":return w2(a,b);case "long":return v2(a,b);default:throw Error(["No matching clause: ",$CLJS.p.h(d)].join(""));}});
$CLJS.Q0.m(null,$CLJS.YJ,function(a,b,c){c=$CLJS.O(c);a=$CLJS.J.g(c,$CLJS.kG);b=$CLJS.J.g(c,$CLJS.ZJ);c=$CLJS.J.g(c,$CLJS.ci);a=new $CLJS.h(null,3,[$CLJS.H0,$CLJS.cB(a),$CLJS.wE,w2(a,b),$CLJS.l0,v2(a,b)],null);return $CLJS.n(c)?$CLJS.R.j(a,$CLJS.ci,!0):a});