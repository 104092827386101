var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var CH,DH,Ifa,FH,Jfa,Kfa,Lfa,GH,EH;$CLJS.AH=function(a,b){return $CLJS.gc($CLJS.fb(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.hg.j(c,e,$CLJS.be.g($CLJS.J.j(c,e,$CLJS.Cf),d))},$CLJS.ec($CLJS.N),b))};$CLJS.BH=function(a,b){return $CLJS.fb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Sc(d):null},null,b)};
CH=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);b=$CLJS.yC(b,$CLJS.ZC)?$CLJS.lH:$CLJS.yC(b,$CLJS.YD)?$CLJS.mE:$CLJS.yC(b,$CLJS.yD)?$CLJS.nE:null;return $CLJS.n(b)?$CLJS.yF(b,a):!0};
DH=function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);$CLJS.A(a);a=$CLJS.B(a);a=$CLJS.AH(function(d){return $CLJS.yC($CLJS.WE(d),$CLJS.jD)},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,!1);b=$CLJS.J.g(b,!0);if($CLJS.Lk.g($CLJS.D(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.D(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.WE($CLJS.A(a));return $CLJS.Re(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);$CLJS.I(d,2,null);
var e=$CLJS.I(d,3,null);return $CLJS.n(CH(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
Ifa=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,1,[$CLJS.wt,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ks,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zr,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.GE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.is,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EH],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.is,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.yt,function(b){b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.Cj);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(DH(b))].join("")}],null),$CLJS.Te(DH)],null)],null)};
FH=function(a){var b=$CLJS.Q,c=Ifa(a);a=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ks,new $CLJS.h(null,1,[$CLJS.wt,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zr,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.GE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.is,new $CLJS.h(null,1,[$CLJS.Xn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null)],null)],null);
return new $CLJS.P(null,3,5,b,[$CLJS.ws,c,a],null)};Jfa=function(a){return $CLJS.Qd($CLJS.BF,$CLJS.hf.g(function(b){var c=$CLJS.WE(b),d=$CLJS.yC(c,$CLJS.hF);b=d?$CLJS.yF($CLJS.vG,b):d;return $CLJS.n(b)?$CLJS.Nj:c},a))};Kfa=function(a){a=$CLJS.BH(function(b){return!$CLJS.yC(b,$CLJS.jD)},$CLJS.hf.g($CLJS.WE,a));return $CLJS.yC(a,$CLJS.hF)?$CLJS.Uj:a};Lfa=function(a){return $CLJS.n($CLJS.Re(function(b){return $CLJS.yC($CLJS.WE(b),$CLJS.jD)},a))?Kfa(a):Jfa(a)};
GH=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);$CLJS.HH=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);EH=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.X(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ns,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.as,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,EH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.wt,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=$CLJS.WE(a);return $CLJS.Qe(function(d){return CH(d,c)},b)}],null)],null));
$CLJS.X(GH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.is,new $CLJS.h(null,1,[$CLJS.Xn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null)],null));$CLJS.RG.g($CLJS.as,FH($CLJS.as));$CLJS.RG.g($CLJS.pt,FH($CLJS.pt));$CLJS.IF($CLJS.bs,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bt,GH],null)]));$CLJS.IF($CLJS.MG,$CLJS.H([$CLJS.pt,$CLJS.VD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bt,GH],null)]));
for(var IH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.as,$CLJS.pt,$CLJS.bs],null)),JH=null,KH=0,LH=0;;)if(LH<KH){var Mfa=JH.X(null,LH);$CLJS.zF(Mfa,$CLJS.HH);LH+=1}else{var MH=$CLJS.y(IH);if(MH){var NH=MH;if($CLJS.Ad(NH)){var OH=$CLJS.lc(NH),Nfa=$CLJS.mc(NH),Ofa=OH,Pfa=$CLJS.D(OH);IH=Nfa;JH=Ofa;KH=Pfa}else{var Qfa=$CLJS.A(NH);$CLJS.zF(Qfa,$CLJS.HH);IH=$CLJS.B(NH);JH=null;KH=0}LH=0}else break}$CLJS.VE.m(null,$CLJS.HH,function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);$CLJS.A(a);a=$CLJS.B(a);return Lfa(a)});
$CLJS.GF($CLJS.SF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null)]));$CLJS.zF($CLJS.SF,$CLJS.lF);
for(var PH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OG,$CLJS.JF,$CLJS.eG],null)),QH=null,RH=0,SH=0;;)if(SH<RH){var Rfa=QH.X(null,SH);$CLJS.GF(Rfa,$CLJS.H([$CLJS.pt,$CLJS.VD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null)]));SH+=1}else{var TH=$CLJS.y(PH);if(TH){var UH=TH;if($CLJS.Ad(UH)){var VH=$CLJS.lc(UH),Sfa=$CLJS.mc(UH),Tfa=VH,Ufa=$CLJS.D(VH);PH=Sfa;QH=Tfa;RH=Ufa}else{var Vfa=$CLJS.A(UH);$CLJS.GF(Vfa,$CLJS.H([$CLJS.pt,$CLJS.VD,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null)]));PH=$CLJS.B(UH);QH=null;RH=0}SH=0}else break}
for(var WH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NF,$CLJS.pG,$CLJS.wG],null)),XH=null,YH=0,ZH=0;;)if(ZH<YH){var Wfa=XH.X(null,ZH);$CLJS.GF(Wfa,$CLJS.H([$CLJS.pt,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null)]));ZH+=1}else{var $H=$CLJS.y(WH);if($H){var aI=$H;if($CLJS.Ad(aI)){var bI=$CLJS.lc(aI),Xfa=$CLJS.mc(aI),Yfa=bI,Zfa=$CLJS.D(bI);WH=Xfa;XH=Yfa;YH=Zfa}else{var $fa=$CLJS.A(aI);$CLJS.GF($fa,$CLJS.H([$CLJS.pt,$CLJS.vj,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null)]));WH=$CLJS.B(aI);XH=null;YH=0}ZH=0}else break}$CLJS.GF($CLJS.PF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null)]));
$CLJS.VE.m(null,$CLJS.PF,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.yC($CLJS.WE(b),$CLJS.vj)&&$CLJS.yC($CLJS.WE(a),$CLJS.vj)?$CLJS.vj:$CLJS.VD});