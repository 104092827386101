var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.breakout.js");require("./metabase.lib.expression.js");require("./metabase.lib.field.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var pra,qra,y7,rra,sra,tra,ura,vra,wra,xra,z7,A7,B7,yra,C7;
pra=function(a){return function(b){var c=$CLJS.$e($CLJS.bh);return function(){function d(l,m){var t=a.h?a.h(m):a.call(null,m);if($CLJS.Hd($CLJS.q(c),t))return l;c.Dd(null,$CLJS.be.g(c.Qb(null),t));return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);
};k.o=f;k.h=e;k.g=d;return k}()}};qra=function(a,b,c){return $CLJS.eg.j($CLJS.Cf,$CLJS.qE(function(d){return $CLJS.U0.v(a,b,d,new $CLJS.h(null,2,[$CLJS.E0,c,$CLJS.y0,!1],null))}),$CLJS.MN.h($CLJS.OW(a,b)))};y7=function(a,b,c){return $CLJS.eg.j($CLJS.Cf,$CLJS.qE(function(d){return $CLJS.S0.v(a,b,d,c)}),$CLJS.MN.h($CLJS.OW(a,b)))};$CLJS.a4=function(a,b){return $CLJS.fb(function(c,d){return $CLJS.ZW.l(c,d,$CLJS.R,$CLJS.H([$CLJS.p7,$CLJS.S0.j(c,d,$CLJS.OW(c,d))]))},a,$CLJS.zu(0,$CLJS.NW(a,b)))};
rra=function(a,b){a=$CLJS.OW(a,b);a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.du),d=$CLJS.J.g(a,$CLJS.XV);b=$CLJS.p7.h(a);if($CLJS.n(b))return b;var e=$CLJS.wV.h(a);if($CLJS.n(e)&&$CLJS.n(function(){var k=$CLJS.E.g(c,$CLJS.kW);return k?k:d}())){var f=function(){var k=c instanceof $CLJS.M?c.T:null;switch(k){case "mbql.stage/native":return $CLJS.c_;case "mbql.stage/mbql":return $CLJS.XZ;default:throw Error(["No matching clause: ",$CLJS.p.h(k)].join(""));}}();return $CLJS.Fe(function(){return function m(l){return new $CLJS.ne(null,
function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.Ad(t)){var u=$CLJS.lc(t),v=$CLJS.D(u),x=$CLJS.qe(v);a:for(var z=0;;)if(z<v){var C=$CLJS.kd(u,z);C=$CLJS.el.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.GZ,$CLJS.T.h(C),$CLJS.UZ,$CLJS.T.h(C)],null),C,new $CLJS.h(null,1,[$CLJS.VK,f],null)]));x.add(C);z+=1}else{u=!0;break a}return u?$CLJS.te($CLJS.ve(x),m($CLJS.mc(t))):$CLJS.te($CLJS.ve(x),null)}x=$CLJS.A(t);return $CLJS.ee($CLJS.el.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.GZ,$CLJS.T.h(x),$CLJS.UZ,$CLJS.T.h(x)],
null),x,new $CLJS.h(null,1,[$CLJS.VK,f],null)])),m($CLJS.Lc(t)))}return null}},null,null)}($CLJS.LW.h(e))}())}return null};
sra=function(a,b,c){return $CLJS.Fe(function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.kd(l,v),z=t,C=$CLJS.R,G=C.l,K=x,S=$CLJS.FA($CLJS.GZ,$CLJS.T)(x);x=$CLJS.O1(a,x);x=c.h?c.h(x):c.call(null,x);C=G.call(C,K,$CLJS.VK,$CLJS.iL,$CLJS.H([$CLJS.GZ,S,$CLJS.UZ,x]));z.add(C);v+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.A(k);return $CLJS.ee($CLJS.R.l(u,$CLJS.VK,$CLJS.iL,$CLJS.H([$CLJS.GZ,$CLJS.FA($CLJS.GZ,$CLJS.T)(u),$CLJS.UZ,function(){var v=$CLJS.O1(a,u);return c.h?c.h(v):c.call(null,v)}()])),f($CLJS.Lc(k)))}return null}},null,null)}($CLJS.X5.g(a,b))}())};
tra=function(a,b,c){return $CLJS.Fe(function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.kd(l,v),z=t,C=$CLJS.R,G=C.l,K=x,S=$CLJS.T.h(x);x=$CLJS.T.h(x);x=c.h?c.h(x):c.call(null,x);C=G.call(C,K,$CLJS.VK,$CLJS.oL,$CLJS.H([$CLJS.GZ,S,$CLJS.UZ,x]));z.add(C);v+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),null)}var u=
$CLJS.A(k);return $CLJS.ee($CLJS.R.l(u,$CLJS.VK,$CLJS.oL,$CLJS.H([$CLJS.GZ,$CLJS.T.h(u),$CLJS.UZ,function(){var v=$CLJS.T.h(u);return c.h?c.h(v):c.call(null,v)}()])),f($CLJS.Lc(k)))}return null}},null,null)}($CLJS.J4.g(a,b))}())};
ura=function(a,b,c){var d=$CLJS.OW(a,b);if($CLJS.n(d)){d=$CLJS.O(d);var e=$CLJS.J.g(d,$CLJS.aF);return $CLJS.Fe(function(){return function l(k){return new $CLJS.ne(null,function(){for(;;){var m=$CLJS.y(k);if(m){if($CLJS.Ad(m)){var t=$CLJS.lc(m),u=$CLJS.D(t),v=$CLJS.qe(u);return function(){for(var K=0;;)if(K<u){var S=$CLJS.kd(t,K),V=$CLJS.I(S,0,null),Z=function(){var ra=V;ra=ra instanceof $CLJS.M?ra.T:null;switch(ra){case "field":return $CLJS.b_;case "expression":return $CLJS.OZ;default:throw Error(["No matching clause: ",
$CLJS.p.h(ra)].join(""));}}(),ha=$CLJS.i0.j(a,b,S);$CLJS.ue(v,$CLJS.R.l(ha,$CLJS.VK,Z,$CLJS.H([$CLJS.GZ,$CLJS.L0.j(a,b,ha),$CLJS.UZ,function(){var ra=$CLJS.O1(a,ha);return c.h?c.h(ra):c.call(null,ra)}()])));K+=1}else return!0}()?$CLJS.te($CLJS.ve(v),l($CLJS.mc(m))):$CLJS.te($CLJS.ve(v),null)}var x=$CLJS.A(m),z=$CLJS.I(x,0,null),C=function(){var K=z;K=K instanceof $CLJS.M?K.T:null;switch(K){case "field":return $CLJS.b_;case "expression":return $CLJS.OZ;default:throw Error(["No matching clause: ",$CLJS.p.h(K)].join(""));
}}(),G=$CLJS.i0.j(a,b,x);return $CLJS.ee($CLJS.R.l(G,$CLJS.VK,C,$CLJS.H([$CLJS.GZ,$CLJS.L0.j(a,b,G),$CLJS.UZ,function(){var K=$CLJS.O1(a,G);return c.h?c.h(K):c.call(null,K)}()])),l($CLJS.Lc(m)))}return null}},null,null)}(e)}())}return null};vra=function(a,b,c){return $CLJS.Fe($CLJS.eg.j($CLJS.Cf,$CLJS.qE(function(d){return d.j?d.j(a,b,c):d.call(null,a,b,c)}),new $CLJS.P(null,2,5,$CLJS.Q,[sra,tra],null)))};
wra=function(a,b,c){var d=$CLJS.j2(a,b);return $CLJS.n(d)?$CLJS.Fe(function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);return function(){for(var z=0;;)if(z<t){var C=$CLJS.kd(m,z);var G=$CLJS.FA($CLJS.UZ,$CLJS.GZ)(C);G=$CLJS.n(G)?G:$CLJS.L0.j(a,b,C);C=$CLJS.Fk.l($CLJS.el.l($CLJS.H([C,new $CLJS.h(null,3,[$CLJS.VK,$CLJS.HZ,$CLJS.GZ,G,$CLJS.UZ,c.h?c.h(G):c.call(null,G)],null),$CLJS.n($CLJS.T1.h(C))?
$CLJS.cm(C,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.T1],null)):null])),$CLJS.SW,$CLJS.H([$CLJS.AE]));u.add(C);z+=1}else return!0}()?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}var v=$CLJS.A(l),x=function(){var z=$CLJS.FA($CLJS.UZ,$CLJS.GZ)(v);return $CLJS.n(z)?z:$CLJS.L0.j(a,b,v)}();return $CLJS.ee($CLJS.Fk.l($CLJS.el.l($CLJS.H([v,new $CLJS.h(null,3,[$CLJS.VK,$CLJS.HZ,$CLJS.GZ,x,$CLJS.UZ,c.h?c.h(x):c.call(null,x)],null),$CLJS.n($CLJS.T1.h(v))?$CLJS.cm(v,new $CLJS.P(null,1,5,$CLJS.Q,
[$CLJS.T1],null)):null])),$CLJS.SW,$CLJS.H([$CLJS.AE])),k($CLJS.Lc(l)))}return null}},null,null)}($CLJS.S0.j(a,d,$CLJS.OW(a,d)))}()):null};xra=function(a,b,c,d){return $CLJS.n(c)?(c=$CLJS.f0(a,c),$CLJS.n(c)?$CLJS.Fe($CLJS.U0.v(a,b,c,d)):null):null};
z7=function(a,b,c){return $CLJS.Fe(function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.kd(l,v),z=t;var C=$CLJS.xB.h(x);var G=$CLJS.R.l,K=x,S=$CLJS.T.h(x);x=$CLJS.T.h(x);x=c.h?c.h(x):c.call(null,x);C=$CLJS.h2(G.call($CLJS.R,K,$CLJS.VK,$CLJS.OZ,$CLJS.H([$CLJS.GZ,S,$CLJS.UZ,x])),$CLJS.Ei,$CLJS.n(C)?C:$CLJS.zj);z.add(C);v+=1}else return!0}()?$CLJS.te($CLJS.ve(t),
f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),null)}var u=$CLJS.A(k);return $CLJS.ee(function(){var v=$CLJS.xB.h(u),x=$CLJS.R.l,z=u,C=$CLJS.T.h(u);var G=$CLJS.T.h(u);G=c.h?c.h(G):c.call(null,G);return $CLJS.h2(x.call($CLJS.R,z,$CLJS.VK,$CLJS.OZ,$CLJS.H([$CLJS.GZ,C,$CLJS.UZ,G])),$CLJS.Ei,$CLJS.n(v)?v:$CLJS.zj)}(),f($CLJS.Lc(k)))}return null}},null,null)}($CLJS.Qna.g(a,b))}())};
A7=function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.E0);return $CLJS.Pk.g(function(f){return $CLJS.Fk.l(f,$CLJS.h_,$CLJS.H([$CLJS.SW,$CLJS.YW,$CLJS.fL]))},function(){var f=wra(a,b,e);if($CLJS.n(f))return f;f=$CLJS.OW(a,b);f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.nP),l=$CLJS.J.g(f,$CLJS.XV),m=$CLJS.n(k)?function(){var t=$CLJS.d0(a,k);return $CLJS.U0.v(a,b,t,d)}():null;if($CLJS.n(m))return m;l=$CLJS.n(l)?xra(a,b,l,$CLJS.R.j(d,$CLJS.y0,!1)):null;return $CLJS.n(l)?l:function v(u){return new $CLJS.ne(null,
function(){for(;;){var x=$CLJS.y(u);if(x){if($CLJS.Ad(x)){var z=$CLJS.lc(x),C=$CLJS.D(z),G=$CLJS.qe(C);return function(){for(var S=0;;)if(S<C){var V=$CLJS.kd(z,S),Z=G,ha=$CLJS.R,ra=ha.l,Na=V,zb=$CLJS.T.h(V);V=$CLJS.T.h(V);V=e.h?e.h(V):e.call(null,V);ha=ra.call(ha,Na,$CLJS.VK,$CLJS.c_,$CLJS.H([$CLJS.GZ,zb,$CLJS.UZ,V]));Z.add(ha);S+=1}else return!0}()?$CLJS.te($CLJS.ve(G),v($CLJS.mc(x))):$CLJS.te($CLJS.ve(G),null)}var K=$CLJS.A(x);return $CLJS.ee($CLJS.R.l(K,$CLJS.VK,$CLJS.c_,$CLJS.H([$CLJS.GZ,$CLJS.T.h(K),
$CLJS.UZ,function(){var S=$CLJS.T.h(K);return e.h?e.h(S):e.call(null,S)}()])),v($CLJS.Lc(x)))}return null}},null,null)}($CLJS.LW.h($CLJS.wV.h(f)))}())};
B7=function(a,b){var c=$CLJS.I(a,0,null);$CLJS.I(a,1,null);var d=$CLJS.I(a,2,null);switch(c instanceof $CLJS.M?c.T:null){case "field":if("number"===typeof d||"string"===typeof d)return $CLJS.E.g(d,$CLJS.Yi.h(b));throw $CLJS.hi("unknown type of :field ref in lib.stage/ref-to?",new $CLJS.h(null,2,[$CLJS.iS,a,$CLJS.Si,b],null));case "expression":return $CLJS.E.g(d,$CLJS.T.h(b));default:throw $CLJS.hi("unknown clause in lib.stage/ref-to?",new $CLJS.h(null,2,[$CLJS.iS,a,$CLJS.Si,b],null));}};
yra=function(a,b,c){var d=$CLJS.fG.h($CLJS.OW(a,b));return $CLJS.n(d)?function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){var m=l;if($CLJS.Ad(m)){var t=$CLJS.lc(m),u=$CLJS.D(t),v=$CLJS.qe(u);return function(){for(var z=0;;)if(z<u){var C=$CLJS.kd(t,z);$CLJS.ue(v,function(){var G=$CLJS.BH(function(V,Z){return function(ha){return B7(ha,Z)}}(z,C,t,u,v,m,l,d,d),d);if($CLJS.n(G)){var K=$CLJS.l7(G);G=$CLJS.P2(G);G=$CLJS.O(G);G=$CLJS.J.g(G,$CLJS.ri);var S=C;K=$CLJS.n(K)?$CLJS.w5(S,
K):S;return $CLJS.n(G)?$CLJS.H3(K,G):K}return C}());z+=1}else return!0}()?$CLJS.te($CLJS.ve(v),k($CLJS.mc(m))):$CLJS.te($CLJS.ve(v),null)}var x=$CLJS.A(m);return $CLJS.ee(function(){var z=$CLJS.BH(function(K){return function(S){return B7(S,K)}}(x,m,l,d,d),d);if($CLJS.n(z)){var C=$CLJS.l7(z);z=$CLJS.P2(z);z=$CLJS.O(z);z=$CLJS.J.g(z,$CLJS.ri);var G=x;C=$CLJS.n(C)?$CLJS.w5(G,C):G;return $CLJS.n(z)?$CLJS.H3(C,z):C}return x}(),k($CLJS.Lc(m)))}return null}},null,null)}(c):c};
C7=new $CLJS.M("metabase.lib.stage","stage","metabase.lib.stage/stage",1448689281);$CLJS.upa={};$CLJS.zF($CLJS.dW,C7);$CLJS.zF($CLJS.kW,C7);$CLJS.z1.m(null,$CLJS.dW,function(a){return $CLJS.y1(a,new $CLJS.h(null,2,[$CLJS.gG,$CLJS.Ve($CLJS.Pk,$CLJS.z1),$CLJS.rW,$CLJS.Ve($CLJS.Pk,$CLJS.z1)],null))});$CLJS.P0.m(null,C7,function(){throw $CLJS.hi("You can't calculate a metadata map for a stage! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.T0.m(null,C7,function(a,b,c,d){d=$CLJS.O(d);c=$CLJS.J.g(d,$CLJS.E0);var e=$CLJS.J.g(d,$CLJS.y0);a=$CLJS.a4(a,b);var f=$CLJS.O(d);var k=$CLJS.J.g(f,$CLJS.E0),l=$CLJS.J.g(f,$CLJS.C0),m=$CLJS.J.g(f,$CLJS.I0);f=$CLJS.gf.l(A7(a,b,f),$CLJS.n(m)?z7(a,b,k):null,$CLJS.H([$CLJS.n(l)?qra(a,b,k):null]));k=$CLJS.gf.g;d=$CLJS.n(e)?(e=$CLJS.Va($CLJS.XV.h($CLJS.OW(a,b))))?e:$CLJS.G0.h(d):e;return yra(a,b,k.call($CLJS.gf,f,$CLJS.n(d)?$CLJS.P1(a,b,f,c):null))});
$CLJS.R0.m(null,C7,function(a,b,c,d){c=$CLJS.O(d);d=$CLJS.J.g(c,$CLJS.E0);var e=rra(a,b);if($CLJS.n(e))return e;a=$CLJS.a4(a,b);e=vra(a,b,d);var f=ura(a,b,d);return $CLJS.n(e)?$CLJS.eg.g(e,f):$CLJS.n(f)?($CLJS.mh(f),$CLJS.eg.j($CLJS.Cf,pra(function(k){return $CLJS.Fk.l(k,$CLJS.f_,$CLJS.H([$CLJS.VK,$CLJS.m1,$CLJS.UZ]))}),$CLJS.gf.g(f,y7(a,b,c)))):$CLJS.gf.l(A7(a,b,new $CLJS.h(null,2,[$CLJS.y0,!1,$CLJS.E0,d],null)),z7(a,b,d),$CLJS.H([y7(a,b,c)]))});$CLJS.J0.m(null,$CLJS.kW,function(){return $CLJS.TE("Native query")});
var D7=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nP,$CLJS.XV,$CLJS.MN],null),E7=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.gG,$CLJS.fG,$CLJS.rW,$CLJS.YR,$CLJS.uS],null);
$CLJS.J0.m(null,$CLJS.dW,function(a,b,c,d){var e=$CLJS.a4(a,b);a=$CLJS.Fe(function(){var f=$CLJS.eg.j($CLJS.N,$CLJS.Nk.g($CLJS.xC,$CLJS.hf.h(function(l){return new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.mna.j(e,b,l)],null)})),new $CLJS.P(null,2,5,$CLJS.Q,[D7,E7],null)),k=$CLJS.Ht(" + ",$CLJS.Ok.g($CLJS.KA,$CLJS.hf.g(f,D7)));f=$CLJS.hf.g(f,E7);return $CLJS.Ht(", ",$CLJS.Ok.g($CLJS.KA,$CLJS.ee(k,f)))}());if($CLJS.n(a))return a;a=$CLJS.j2(e,b);return $CLJS.n(a)?$CLJS.j0.v(e,a,$CLJS.OW(e,a),d):null});