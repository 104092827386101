var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var o4,q4,r4,s4,Npa;o4=function(a,b,c){var d=$CLJS.OW(a,b);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.gG);d=$CLJS.BH($CLJS.Nk.j($CLJS.ch([c]),$CLJS.IE,$CLJS.hd),d);if(!$CLJS.n(d))throw $CLJS.hi($CLJS.rE("No aggregation with uuid {0}",$CLJS.H([c])),new $CLJS.h(null,3,[$CLJS.Ls,c,$CLJS.OF,a,$CLJS.LL,b],null));return d};$CLJS.p4=function(a){return $CLJS.i2($CLJS.EG,new $CLJS.P(null,1,5,$CLJS.Q,[a],null))};
q4=new $CLJS.M("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);r4=new $CLJS.M("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);s4=new $CLJS.M("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);Npa=new $CLJS.M(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.M0.m(null,$CLJS.gG,function(a,b){var c=$CLJS.Fe($CLJS.gG.h($CLJS.OW(a,b)));return $CLJS.n(c)?$CLJS.k2($CLJS.TE("and"),function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.j0.v(a,b,v,$CLJS.k0);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),null)}t=$CLJS.A(k);return $CLJS.ee($CLJS.j0.v(a,
b,t,$CLJS.k0),f($CLJS.Lc(k)))}return null}},null,null)}(c)}()):null});$CLJS.P0.m(null,$CLJS.gG,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.xB);e=$CLJS.J.g(e,$CLJS.Ei);c=$CLJS.I(c,2,null);c=o4(a,b,c);return $CLJS.el.l($CLJS.H([$CLJS.i0.j(a,b,c),new $CLJS.h(null,2,[$CLJS.VK,$CLJS.oL,$CLJS.m1,$CLJS.IE.h($CLJS.hd(c))],null),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.xB,d],null):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.Ei,e],null):null]))});
$CLJS.J0.m(null,$CLJS.gG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.j0.v(a,b,o4(a,b,c),d)});$CLJS.zF(r4,s4);
for(var t4=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xx,$CLJS.sG],null)),u4=null,v4=0,w4=0;;)if(w4<v4){var Opa=u4.X(null,w4);$CLJS.zF(Opa,r4);w4+=1}else{var x4=$CLJS.y(t4);if(x4){var y4=x4;if($CLJS.Ad(y4)){var z4=$CLJS.lc(y4),Ppa=$CLJS.mc(y4),Qpa=z4,Rpa=$CLJS.D(z4);t4=Ppa;u4=Qpa;v4=Rpa}else{var Spa=$CLJS.A(y4);$CLJS.zF(Spa,r4);t4=$CLJS.B(y4);u4=null;v4=0}w4=0}else break}
$CLJS.J0.m(null,r4,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);if($CLJS.n(c))switch(a=$CLJS.j0.v(a,b,c,d),e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.rE("Count of {0}",$CLJS.H([a]));case "cum-count":return $CLJS.rE("Cumulative count of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}else switch(e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.TE("Count");case "cum-count":return $CLJS.TE("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.K0.m(null,r4,function(a,b,c){a=$CLJS.I(c,0,null);a=a instanceof $CLJS.M?a.T:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});$CLJS.P0.m(null,r4,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.tI($CLJS.P0,s4);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.Tj,$CLJS.$C)});$CLJS.J0.m(null,$CLJS.aG,function(){return $CLJS.TE("Case")});
$CLJS.K0.m(null,$CLJS.aG,function(){return"case"});$CLJS.zF(q4,s4);for(var A4=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.lG,$CLJS.NG,$CLJS.XF,$CLJS.hk,$CLJS.FG,$CLJS.Xn,$CLJS.GG,$CLJS.EG,$CLJS.YF],null)),B4=null,C4=0,D4=0;;)if(D4<C4){var Tpa=B4.X(null,D4);$CLJS.zF(Tpa,q4);D4+=1}else{var E4=$CLJS.y(A4);if(E4){var F4=E4;if($CLJS.Ad(F4)){var G4=$CLJS.lc(F4),Upa=$CLJS.mc(F4),Vpa=G4,Wpa=$CLJS.D(G4);A4=Upa;B4=Vpa;C4=Wpa}else{var Xpa=$CLJS.A(F4);$CLJS.zF(Xpa,q4);A4=$CLJS.B(F4);B4=null;C4=0}D4=0}else break}
$CLJS.K0.m(null,q4,function(a,b,c){a=$CLJS.I(c,0,null);$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=a instanceof $CLJS.M?a.T:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.J0.m(null,q4,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);a=$CLJS.j0.v(a,b,c,d);e=e instanceof $CLJS.M?e.T:null;switch(e){case "avg":return $CLJS.rE("Average of {0}",$CLJS.H([a]));case "cum-sum":return $CLJS.rE("Cumulative sum of {0}",$CLJS.H([a]));case "distinct":return $CLJS.rE("Distinct values of {0}",$CLJS.H([a]));case "max":return $CLJS.rE("Max of {0}",$CLJS.H([a]));case "median":return $CLJS.rE("Median of {0}",$CLJS.H([a]));case "min":return $CLJS.rE("Min of {0}",
$CLJS.H([a]));case "stddev":return $CLJS.rE("Standard deviation of {0}",$CLJS.H([a]));case "sum":return $CLJS.rE("Sum of {0}",$CLJS.H([a]));case "var":return $CLJS.rE("Variance of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.J0.m(null,$CLJS.cG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);c=$CLJS.I(c,3,null);return $CLJS.rE("{0}th percentile of {1}",$CLJS.H([c,$CLJS.j0.v(a,b,e,d)]))});$CLJS.K0.m(null,$CLJS.cG,function(){return"percentile"});
$CLJS.zF($CLJS.cG,s4);$CLJS.J0.m(null,$CLJS.LG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.rE("Sum of {0} matching condition",$CLJS.H([$CLJS.j0.v(a,b,e,d)]))});$CLJS.K0.m(null,$CLJS.LG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return["sum_where_",$CLJS.p.h($CLJS.L0.j(a,b,d))].join("")});$CLJS.zF($CLJS.LG,s4);$CLJS.J0.m(null,$CLJS.yG,function(){return $CLJS.TE("Share of rows matching condition")});
$CLJS.K0.m(null,$CLJS.yG,function(){return"share"});$CLJS.zF($CLJS.yG,s4);$CLJS.J0.m(null,$CLJS.AG,function(){return $CLJS.TE("Count of rows matching condition")});$CLJS.K0.m(null,$CLJS.AG,function(){return"count-where"});$CLJS.zF($CLJS.AG,s4);
$CLJS.P0.m(null,s4,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.el.l;d=$CLJS.n(d)?$CLJS.cm($CLJS.i0.j(a,b,d),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.hL],null)):null;var f=$CLJS.tI($CLJS.P0,$CLJS.ci);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.el,$CLJS.H([d,a]))});
$CLJS.Ypa=function(){function a(d){return $CLJS.i2($CLJS.Xx,new $CLJS.P(null,1,5,$CLJS.Q,[d],null))}function b(){return $CLJS.i2($CLJS.Xx,$CLJS.Cf)}var c=null;c=function(d){switch(arguments.length){case 0:return b.call(this);case 1:return a.call(this,d)}throw Error("Invalid arity: "+arguments.length);};c.o=b;c.h=a;return c}();$CLJS.xW.m(null,$CLJS.gG,function(a){return a});
$CLJS.H4=function(){function a(d,e,f){for(;;)if($CLJS.E.g($CLJS.kC(f),$CLJS.ZK))f=$CLJS.zW(f);else return $CLJS.l2(d,e,$CLJS.gG,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.I4=function(){function a(d,e){return $CLJS.Fe($CLJS.gG.h($CLJS.OW(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.J4=function(){function a(d,e){var f=$CLJS.Fe($CLJS.gG.h($CLJS.OW(d,e)));return null==f?null:$CLJS.eg.j($CLJS.Cf,$CLJS.hf.h(function(k){var l=$CLJS.i0.j(d,e,k),m=$CLJS.R.l,t=$CLJS.xB.h(l);return m.call($CLJS.R,$CLJS.h2(l,$CLJS.Ei,$CLJS.n(t)?t:$CLJS.zj),$CLJS.VK,$CLJS.oL,$CLJS.H([$CLJS.m1,$CLJS.IE.h($CLJS.hd(k))]))}),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.g=a;return c}();$CLJS.J0.m(null,$CLJS.$G,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.WG);return $CLJS.wE.h(a.o?a.o():a.call(null))});$CLJS.Q0.m(null,$CLJS.$G,function(a,b,c){var d=$CLJS.O(c);a=$CLJS.J.g(d,$CLJS.kG);b=$CLJS.J.g(d,$CLJS.WG);c=$CLJS.J.g(d,$CLJS.UG);d=$CLJS.J.g(d,$CLJS.VZ);a=$CLJS.R.l(b.o?b.o():b.call(null),$CLJS.H0,$CLJS.cB(a),$CLJS.H([$CLJS.F0,c]));return null!=d?$CLJS.R.j(a,$CLJS.r0,d):a});
$CLJS.Zpa=function(){function a(d,e){var f=function(){var m=$CLJS.lA.h($CLJS.D1($CLJS.c0(d)));return $CLJS.n(m)?m:$CLJS.bh}(),k=$CLJS.OW(d,e),l=$CLJS.U0.j(d,e,k);return $CLJS.Fe($CLJS.eg.j($CLJS.Cf,$CLJS.Nk.j($CLJS.kf(function(m){m=$CLJS.YG.h(m);var t=null==m;return t?t:f.h?f.h(m):f.call(null,m)}),$CLJS.Vl(function(m){m=$CLJS.O(m);var t=$CLJS.J.g(m,$CLJS.UG),u=$CLJS.J.g(m,$CLJS.SG);if($CLJS.Va(t))return m;if($CLJS.E.g(u,$CLJS.Ns))return $CLJS.R.j(m,$CLJS.LW,l);t=$CLJS.Fe($CLJS.h1(function(v){return $CLJS.F_(u,
v)},l));return $CLJS.n(t)?$CLJS.R.j(m,$CLJS.LW,t):null}),$CLJS.hf.h(function(m){return $CLJS.R.j(m,$CLJS.du,$CLJS.$G)})),$CLJS.aH))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.$pa=function(){function a(d,e){return $CLJS.yW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kG.h(d),$CLJS.N,$CLJS.AW.h(e)],null))}function b(d){if($CLJS.Va($CLJS.UG.h(d)))return $CLJS.yW(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kG.h(d),$CLJS.N],null));var e=$CLJS.kG.h(d);e=$CLJS.fa.g?$CLJS.fa.g("aggregation operator %s requires an argument",e):$CLJS.fa.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.hi(e,new $CLJS.h(null,1,[Npa,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();