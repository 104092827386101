var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var NY,Yla,PY,Zla,$la,ama,RY,cma,dma,ema,fma,UY,VY,WY,XY,ZY,hma,$Y,aZ,ima,jma,kma,TY,bma,bZ,cZ,gma,eZ;NY=function(a,b){var c=$CLJS.y(b);for(a=$CLJS.y($CLJS.bf(a,b));;)if(a)c=$CLJS.B(c),a=$CLJS.B(a);else return c};
Yla=function(){return function(){function a(k,l,m){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj.j?$CLJS.lj.j(k,l,m):$CLJS.lj.call(null,k,l,m),$CLJS.kl.j?$CLJS.kl.j(k,l,m):$CLJS.kl.call(null,k,l,m)],null)}function b(k,l){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj.g?$CLJS.lj.g(k,l):$CLJS.lj.call(null,k,l),$CLJS.kl.g?$CLJS.kl.g(k,l):$CLJS.kl.call(null,k,l)],null)}function c(k){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj.h?$CLJS.lj.h(k):$CLJS.lj.call(null,k),$CLJS.kl.h?$CLJS.kl.h(k):$CLJS.kl.call(null,
k)],null)}function d(){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj.o?$CLJS.lj.o():$CLJS.lj.call(null),$CLJS.kl.o?$CLJS.kl.o():$CLJS.kl.call(null)],null)}var e=null,f=function(){function k(m,t,u,v){var x=null;if(3<arguments.length){x=0;for(var z=Array(arguments.length-3);x<z.length;)z[x]=arguments[x+3],++x;x=new $CLJS.w(z,0,null)}return l.call(this,m,t,u,x)}function l(m,t,u,v){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Se.N($CLJS.lj,m,t,u,v),$CLJS.Se.N($CLJS.kl,m,t,u,v)],null)}k.A=3;k.B=function(m){var t=
$CLJS.A(m);m=$CLJS.B(m);var u=$CLJS.A(m);m=$CLJS.B(m);var v=$CLJS.A(m);m=$CLJS.Lc(m);return l(t,u,v,m)};k.l=l;return k}();e=function(k,l,m,t){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,k);case 2:return b.call(this,k,l);case 3:return a.call(this,k,l,m);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+3],++u;u=new $CLJS.w(v,0,null)}return f.l(k,l,m,u)}throw Error("Invalid arity: "+arguments.length);};e.A=
3;e.B=f.B;e.o=d;e.h=c;e.g=b;e.j=a;e.l=f.l;return e}()};
$CLJS.OY=function(a){return function(b){var c=$CLJS.$e(-1);return function(){function d(l,m){var t=c.Dd(null,c.Qb(null)+1);m=a.g?a.g(t,m):a.call(null,t,m);return null==m?l:b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=f;k.h=e;k.g=d;return k}()}};
PY=function(a){return $CLJS.ZL($CLJS.uW)(a,$CLJS.Cf,$CLJS.Cf)};Zla=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return null==e?d:$CLJS.R.j(d,f,e)},null==b?a:$CLJS.R.j(a,$CLJS.gG,b),$CLJS.mt(2,2,c))};$la=function(a,b){var c=$CLJS.dm(a,$CLJS.PQ);if($CLJS.n(c)){var d=$CLJS.R.j;c=$CLJS.Fb(c);b=$CLJS.Gt.g?$CLJS.Gt.g(c,b):$CLJS.Gt.call(null,c,b);a=d.call($CLJS.R,a,$CLJS.PQ,b)}return a};ama=function(a){return $CLJS.gf.g($CLJS.VV(a),$CLJS.WV(a))};
$CLJS.QY=function(a,b){return $CLJS.GW(a)&&$CLJS.E.g($CLJS.A(a),b)};RY=function(a,b,c){var d=new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.Us,null,$CLJS.rl,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.Bu(2,c):$CLJS.Bu(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?NY(2,c):NY(1,c);var f=$CLJS.I(b,0,null);return $CLJS.y(e)?$CLJS.FX.j(a,e,function(k){var l=$CLJS.R.j(k,f,null);return $CLJS.zd(k)?$CLJS.eg.j($CLJS.Cf,$CLJS.Ok.h($CLJS.yl),l):$CLJS.xd(k)?$CLJS.MA($CLJS.Wa,l):l}):$CLJS.Fk.g(a,f)};
cma=function(a){return $CLJS.fb(function(b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);return RY(b,bma,d)},a,ama(a))};
dma=function(a){for(var b=0;;){var c=$CLJS.F($CLJS.vL.h(a),b);a:{var d=c;for(var e=$CLJS.Cf;;){var f=$CLJS.A($CLJS.hf.g(Yla(),$CLJS.lf($CLJS.Nk.j(SY,$CLJS.A,$CLJS.kl),$CLJS.Ys.h(PY(d)))));if($CLJS.n(f)){var k=f;f=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);var l=RY(d,f,k),m=$CLJS.PA($CLJS.Ky);if($CLJS.n($CLJS.OA("metabase.lib.convert",m))){var t=$CLJS.GB.l($CLJS.H([$CLJS.Fz,$CLJS.Mh.l($CLJS.H([k]))])),u=$CLJS.GB,v=u.l,x=$CLJS.Mh,z=x.l;var C=f;C=$CLJS.n(C)?C:$CLJS.dM(PY(d));$CLJS.NA("metabase.lib.convert",
m,$CLJS.DW("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.H([t,v.call(u,$CLJS.H([$CLJS.Fz,z.call(x,$CLJS.H([C]))])),$CLJS.GB.l($CLJS.H([$CLJS.uz,$CLJS.EW($CLJS.A($CLJS.iu(d,l)))]))])),null)}if($CLJS.E.g(l,d))break a;d=l;e=$CLJS.be.g(e,new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null))}else break a}}d=cma(d);if($CLJS.E.g(c,d)){if($CLJS.E.g(b,$CLJS.D($CLJS.vL.h(a))-1))return a;b+=1}else a=$CLJS.Qk.N(a,$CLJS.vL,$CLJS.R,b,d)}};
ema=function(a){var b=$CLJS.RW();return $CLJS.Pk.g(function(c){return $CLJS.E.g($CLJS.eF.h(c),"__join")?$CLJS.Qk.j(c,$CLJS.eF,b):c},a)};fma=function(a){return"string"===typeof $CLJS.nP.h(a)?$CLJS.Fk.g($CLJS.R.j(a,$CLJS.XV,$CLJS.PW($CLJS.nP.h(a))),$CLJS.nP):a};UY=function(a,b){var c=$CLJS.J.j(a,b,TY);if($CLJS.E.g(c,TY))throw $CLJS.hi(["Unable to find ",$CLJS.Mh.l($CLJS.H([b]))," in map."].join(""),new $CLJS.h(null,2,[gma,a,$CLJS.ax,b],null));return c};
VY=function(){return $CLJS.Ok.h(function(a){var b=$CLJS.I(a,0,null);$CLJS.I(a,1,null);(a=$CLJS.me(b))?(b=$CLJS.ie(b),b=$CLJS.n(b)?$CLJS.E.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};WY=function(a){return $CLJS.eg.j($CLJS.N,VY(),a)};XY=function(a){return $CLJS.Fe($CLJS.eg.j($CLJS.N,$CLJS.Nk.g(VY(),$CLJS.Ok.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.Ei)})),a))};
ZY=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);var d=$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.hf.h($CLJS.YY),c);a=$CLJS.E.g(a,$CLJS.aG)&&$CLJS.B(c)?$CLJS.be.g($CLJS.sd(d),new $CLJS.h(null,1,[$CLJS.ci,$CLJS.rd(d)],null)):d;b=$CLJS.Fe(XY(b));return $CLJS.n(b)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DM,a,b],null):a};hma=function(a){return $CLJS.eg.j($CLJS.Cf,$CLJS.Nk.g($CLJS.hf.h(function(b){return $CLJS.HA(b,$CLJS.LB)}),$CLJS.hf.h($CLJS.YY)),$CLJS.LW.h(a))};
$Y=function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.vL);a=$CLJS.A($CLJS.fb(function(b,c){var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.Q;var f=$CLJS.YY.h(c);d=$CLJS.n(d)?$CLJS.R.j(f,$CLJS.RQ,d):f;d=$CLJS.n(e)?$CLJS.R.j(d,$CLJS.$O,hma(e)):d;return new $CLJS.P(null,2,5,b,[d,$CLJS.wV.h(c)],null)},null,a));return $CLJS.n($CLJS.sQ.h(a))?$CLJS.IV(a,new $CLJS.h(null,1,[$CLJS.sQ,$CLJS.OF],null)):a};
aZ=function(a,b,c){var d=$CLJS.E.g($CLJS.D($CLJS.J.g(a,b)),1)?$CLJS.cX(a,b,$CLJS.Nk.g($CLJS.YY,$CLJS.A)):a;a=1<$CLJS.D($CLJS.J.g(a,b))?$CLJS.cX(d,b,function(e){return $CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Ds],null),$CLJS.hf.h($CLJS.YY),e)}):d;return $CLJS.IV(a,$CLJS.Ee([b,c]))};ima=function(a){var b=$CLJS.XV.h(a);return $CLJS.n(b)?$CLJS.R.j($CLJS.Fk.g(a,$CLJS.XV),$CLJS.nP,["card__",$CLJS.p.h(b)].join("")):a};
jma=new $CLJS.M(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);kma=new $CLJS.M(null,"legacy-ref","legacy-ref",1686404700);TY=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);bma=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);bZ=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);
cZ=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.dZ=new $CLJS.M("lib.convert","converted?","lib.convert/converted?",1937433683);gma=new $CLJS.M(null,"m","m",1632677161);eZ=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var SY,lma,mma,nma,oma,pma,qma,rma,sma,tma,uma;$CLJS.fZ=$CLJS.N;$CLJS.gZ=$CLJS.N;SY=new $CLJS.ah(null,new $CLJS.h(null,7,[$CLJS.rW,null,$CLJS.aF,null,$CLJS.MN,null,$CLJS.hQ,null,$CLJS.fG,null,$CLJS.YR,null,$CLJS.gG,null],null),null);lma=$CLJS.Xe($CLJS.N);mma=$CLJS.Xe($CLJS.N);nma=$CLJS.Xe($CLJS.N);oma=$CLJS.Xe($CLJS.N);pma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.gj,$CLJS.mC],null),$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));
$CLJS.hZ=new $CLJS.di($CLJS.Fh.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.kC,pma,lma,mma,nma,oma);$CLJS.hZ.m(null,$CLJS.ci,function(a){if($CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M){var b=$CLJS.rd(a);var c=$CLJS.xd(b);a=c?$CLJS.sd(a):a;var d=$CLJS.y(a);a=$CLJS.A(d);d=$CLJS.B(d);b=$CLJS.yW($CLJS.eg.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,c?b:$CLJS.N],null),$CLJS.hf.h($CLJS.hZ),d))}else b=a;return b});$CLJS.hZ.m(null,$CLJS.qW,function(a){return a});
$CLJS.hZ.m(null,$CLJS.dW,function(a){var b=$CLJS.hZ.h($CLJS.gG.h(a)),c=$CLJS.Fe($CLJS.Pk.g(function(t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return $CLJS.IW($CLJS.hZ.h(t),u)},$CLJS.hQ.h(a))),d=$CLJS.eg.j($CLJS.N,$CLJS.em(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.IE);return new $CLJS.P(null,2,5,$CLJS.Q,[t,u],null)}),b),e=$CLJS.eg.j($CLJS.N,$CLJS.em(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.IE);return new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null)}),b),f=$CLJS.gZ,k=$CLJS.fZ;$CLJS.gZ=d;$CLJS.fZ=e;try{var l=Zla(fma(a),b,$CLJS.H([$CLJS.hQ,c])),m=$CLJS.fb(function(t,u){return $CLJS.Va($CLJS.J.g(t,u))?t:$CLJS.Qk.j(t,u,$CLJS.hZ)},l,$CLJS.Gk.l(SY,$CLJS.gG,$CLJS.H([$CLJS.hQ])));return $CLJS.n($CLJS.MN.h(m))?$CLJS.Qk.j(m,$CLJS.MN,ema):m}finally{$CLJS.fZ=k,$CLJS.gZ=f}});$CLJS.hZ.m(null,$CLJS.kW,function(a){return $la(a,function(b){return $CLJS.cX(b,$CLJS.LF,$CLJS.hZ)})});
$CLJS.hZ.m(null,$CLJS.qL,function(a){a=$CLJS.Qk.j($CLJS.Qk.j(a,$CLJS.yL,$CLJS.hZ),$CLJS.vL,$CLJS.hZ);var b=$CLJS.n($CLJS.aF.h(a))?$CLJS.Qk.j(a,$CLJS.aF,function(c){return $CLJS.Kl(c)?$CLJS.Pk.g($CLJS.hZ,c):$CLJS.zh.h(c)}):a;return $CLJS.Va($CLJS.eF.h(a))?$CLJS.R.j(b,$CLJS.eF,"__join"):b});$CLJS.hZ.m(null,$CLJS.qB,function(a){return $CLJS.Pk.g($CLJS.hZ,a)});
$CLJS.hZ.m(null,$CLJS.tB,function(a){return $CLJS.n($CLJS.lj.h(a))?dma($CLJS.R.j($CLJS.Qk.j($CLJS.MW(a),$CLJS.vL,function(b){return $CLJS.Pk.g($CLJS.hZ,b)}),$CLJS.dZ,!0)):$CLJS.Gt(a,$CLJS.hZ)});$CLJS.hZ.m(null,$CLJS.rG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.xd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null):new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.yW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rG,a,b],null))});
$CLJS.hZ.m(null,$CLJS.Cj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.IV(a,new $CLJS.h(null,3,[$CLJS.lD,$CLJS.xB,$CLJS.MO,$CLJS.Tj,$CLJS.fS,$CLJS.DE],null));var c=$CLJS.R.j;var d=$CLJS.Ei.h(a);$CLJS.n(d)||(d=$CLJS.xB.h(a),d=$CLJS.n(d)?d:$CLJS.WE(b));a=c.call($CLJS.R,a,$CLJS.Ei,d);return $CLJS.yW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cj,a,b],null))});
$CLJS.hZ.m(null,$CLJS.aG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.ci.h(c);b=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aG,$CLJS.Fk.g(c,$CLJS.ci),$CLJS.Pk.g($CLJS.hZ,b)],null);b=$CLJS.yW(b);return null!=a?$CLJS.be.g(b,$CLJS.hZ.h(a)):b});$CLJS.hZ.m(null,$CLJS.uB,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.yW(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,c],null))});
$CLJS.hZ.m(null,$CLJS.gG,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.yW(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,UY($CLJS.gZ,c)],null))});$CLJS.hZ.m(null,$CLJS.DM,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=$CLJS.hZ.h(b);var c=$CLJS.y(b);b=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);d=$CLJS.B(d);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.el.l($CLJS.H([c,a]))],null),d)});qma=$CLJS.Xe($CLJS.N);rma=$CLJS.Xe($CLJS.N);
sma=$CLJS.Xe($CLJS.N);tma=$CLJS.Xe($CLJS.N);uma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.gj,$CLJS.mC],null),$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));$CLJS.YY=new $CLJS.di($CLJS.Fh.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.kC,uma,qma,rma,sma,tma);
$CLJS.YY.m(null,$CLJS.ci,function(a){if($CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M){a=$CLJS.y(a);var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);if($CLJS.xd(a)){c=$CLJS.eg.g;b=new $CLJS.P(null,1,5,$CLJS.Q,[b],null);var e=$CLJS.gf.g;d=$CLJS.hf.g($CLJS.YY,d);a=XY(a);a=c.call($CLJS.eg,b,e.call($CLJS.gf,d,$CLJS.n(a)?new $CLJS.P(null,1,5,$CLJS.Q,[a],null):null))}else a=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[b],null),$CLJS.hf.g($CLJS.YY,$CLJS.ee(a,d)))}else a=$CLJS.xd(a)?$CLJS.Gt(WY(a),$CLJS.YY):
a;return a});for(var iZ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[eZ,cZ],null)),jZ=null,kZ=0,lZ=0;;)if(lZ<kZ){var vma=jZ.X(null,lZ);$CLJS.zF(vma,bZ);lZ+=1}else{var mZ=$CLJS.y(iZ);if(mZ){var nZ=mZ;if($CLJS.Ad(nZ)){var oZ=$CLJS.lc(nZ),wma=$CLJS.mc(nZ),xma=oZ,yma=$CLJS.D(oZ);iZ=wma;jZ=xma;kZ=yma}else{var zma=$CLJS.A(nZ);$CLJS.zF(zma,bZ);iZ=$CLJS.B(nZ);jZ=null;kZ=0}lZ=0}else break}
for(var pZ=$CLJS.y(new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.Xx,$CLJS.lG,$CLJS.AG,$CLJS.XF,$CLJS.hk,$CLJS.FG,$CLJS.Xn,$CLJS.cG,$CLJS.yG,$CLJS.GG,$CLJS.EG,$CLJS.LG],null)),qZ=null,rZ=0,sZ=0;;)if(sZ<rZ){var Ama=qZ.X(null,sZ);$CLJS.zF(Ama,eZ);sZ+=1}else{var tZ=$CLJS.y(pZ);if(tZ){var uZ=tZ;if($CLJS.Ad(uZ)){var vZ=$CLJS.lc(uZ),Bma=$CLJS.mc(uZ),Cma=vZ,Dma=$CLJS.D(vZ);pZ=Bma;qZ=Cma;rZ=Dma}else{var Ema=$CLJS.A(uZ);$CLJS.zF(Ema,eZ);pZ=$CLJS.B(uZ);qZ=null;rZ=0}sZ=0}else break}
for(var wZ=$CLJS.y($CLJS.yf([$CLJS.as,$CLJS.pt,$CLJS.bs,$CLJS.MG,$CLJS.aG,$CLJS.uG,$CLJS.SF,$CLJS.OG,$CLJS.JF,$CLJS.eG,$CLJS.NF,$CLJS.pG,$CLJS.wG,$CLJS.PF,$CLJS.eJ,$CLJS.gJ,$CLJS.Ly,$CLJS.aJ,$CLJS.iJ,$CLJS.VI,$CLJS.mJ,$CLJS.$I,$CLJS.TI,$CLJS.lJ,$CLJS.tJ,$CLJS.pJ,$CLJS.ZI,$CLJS.wJ,$CLJS.oJ,$CLJS.uJ,$CLJS.RF,$CLJS.KF,$CLJS.PG,$CLJS.eI,$CLJS.tG,$CLJS.Vx,$CLJS.HG,$CLJS.JG,$CLJS.QG,$CLJS.Jt,$CLJS.Pt],!0)),xZ=null,yZ=0,zZ=0;;)if(zZ<yZ){var Fma=xZ.X(null,zZ);$CLJS.zF(Fma,cZ);zZ+=1}else{var AZ=$CLJS.y(wZ);
if(AZ){var BZ=AZ;if($CLJS.Ad(BZ)){var CZ=$CLJS.lc(BZ),Gma=$CLJS.mc(BZ),Hma=CZ,Ima=$CLJS.D(CZ);wZ=Gma;xZ=Hma;yZ=Ima}else{var Jma=$CLJS.A(BZ);$CLJS.zF(Jma,cZ);wZ=$CLJS.B(BZ);xZ=null;yZ=0}zZ=0}else break}$CLJS.YY.m(null,bZ,function(a){return ZY(a)});$CLJS.YY.m(null,$CLJS.tB,function(a){return $CLJS.eg.j($CLJS.N,$CLJS.Nk.g(VY(),$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.YY.h(b)],null)})),a)});
$CLJS.YY.m(null,$CLJS.gG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);if($CLJS.xd(b))try{var d=XY(b),e=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gG,UY($CLJS.fZ,c)],null);return $CLJS.n(d)?$CLJS.be.g(e,d):e}catch(f){throw b=f,c=$CLJS.BV(b),c=$CLJS.fa.g?$CLJS.fa.g("Error converting aggregation reference to pMBQL: %s",c):$CLJS.fa.call(null,"Error converting aggregation reference to pMBQL: %s",c),$CLJS.gi(c,new $CLJS.h(null,1,[$CLJS.W,a],null),b);}else return a});
$CLJS.YY.m(null,$CLJS.qB,function(a){return $CLJS.Pk.g($CLJS.YY,a)});$CLJS.YY.m(null,$CLJS.rG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=null==b||$CLJS.xd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rG,$CLJS.YY.h(a),XY(b)],null)});
$CLJS.YY.m(null,$CLJS.Cj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=XY($CLJS.IV(b,new $CLJS.h(null,3,[$CLJS.xB,$CLJS.lD,$CLJS.Tj,$CLJS.MO,$CLJS.DE,$CLJS.fS],null)));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cj,a,b],null)});
$CLJS.YY.m(null,$CLJS.qL,function(a){var b=WY(a);a=0==$CLJS.eF.h(a).lastIndexOf("__join",0)?$CLJS.Fk.g(b,$CLJS.eF):b;return $CLJS.el.l($CLJS.H([$CLJS.Gt($CLJS.Fk.l(a,$CLJS.vL,$CLJS.H([$CLJS.yL])),$CLJS.YY),aZ($CLJS.cm(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.yL],null)),$CLJS.yL,$CLJS.$R),$Y(a)]))});
$CLJS.YY.m(null,$CLJS.dW,function(a){var b=$CLJS.gG.h(a),c=$CLJS.eg.j($CLJS.N,$CLJS.em(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.IE);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);b=$CLJS.eg.j($CLJS.N,$CLJS.em(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.IE);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.gZ,e=$CLJS.fZ;$CLJS.gZ=c;$CLJS.fZ=b;try{return $CLJS.fb(function(f,k){return $CLJS.cX(f,
k,$CLJS.YY)},aZ($CLJS.cX($CLJS.cX(ima(WY(a)),$CLJS.gG,function(f){return $CLJS.Pk.g(ZY,f)}),$CLJS.hQ,function(f){return $CLJS.eg.g($CLJS.N,function(){return function m(l){return new $CLJS.ne(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.Ad(t)){var u=$CLJS.lc(t),v=$CLJS.D(u),x=$CLJS.qe(v);a:for(var z=0;;)if(z<v){var C=$CLJS.kd(u,z),G=$CLJS.YY.h(C);C=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HW(C),$CLJS.E.g($CLJS.Cj,$CLJS.A(G))?$CLJS.hd(G):G],null);x.add(C);z+=1}else{u=!0;break a}return u?$CLJS.te($CLJS.ve(x),
m($CLJS.mc(t))):$CLJS.te($CLJS.ve(x),null)}x=$CLJS.A(t);u=$CLJS.YY.h(x);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HW(x),$CLJS.E.g($CLJS.Cj,$CLJS.A(u))?$CLJS.hd(u):u],null),m($CLJS.Lc(t)))}return null}},null,null)}(f)}())}),$CLJS.rW,$CLJS.WF),$CLJS.Gk.l(SY,$CLJS.gG,$CLJS.H([$CLJS.rW,$CLJS.hQ])))}finally{$CLJS.fZ=e,$CLJS.gZ=d}});$CLJS.YY.m(null,$CLJS.kW,function(a){return $CLJS.Gt(WY(a),$CLJS.YY)});
$CLJS.YY.m(null,$CLJS.qW,function(a){try{var b=WY(a),c=$CLJS.DN.h(b),d=$Y(b),e=$CLJS.E.g($CLJS.du.h($CLJS.id($CLJS.vL.h(a))),$CLJS.kW)?$CLJS.sQ:$CLJS.OF;return $CLJS.el.l($CLJS.H([$CLJS.Gt($CLJS.Fk.l(b,$CLJS.vL,$CLJS.H([$CLJS.DN,$CLJS.dZ])),$CLJS.YY),function(){var k=$CLJS.Ee([$CLJS.lj,e,e,d]);return $CLJS.y(c)?$CLJS.R.j(k,$CLJS.DN,c):k}()]))}catch(k){var f=k;throw $CLJS.gi(function(){var l=$CLJS.BV(f);return $CLJS.fa.g?$CLJS.fa.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.fa.call(null,
"Error converting MLv2 query to legacy query: %s",l)}(),new $CLJS.h(null,1,[$CLJS.OF,a],null),f);}});
$CLJS.DZ=function(){function a(d,e,f){f=$CLJS.MY(null,$CLJS.Sz(f,$CLJS.H([$CLJS.yi,!0])));var k=$CLJS.OW(d,e);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.gG);k=$CLJS.eg.j($CLJS.N,$CLJS.em(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.IE);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),l);l=$CLJS.eg.j($CLJS.N,$CLJS.em(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.IE);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),l);var m=
$CLJS.gZ,t=$CLJS.fZ;$CLJS.gZ=k;$CLJS.fZ=l;try{try{return $CLJS.hZ.h(f)}catch(v){var u=v;throw $CLJS.gi(function(){var x=$CLJS.BV(u);return $CLJS.fa.g?$CLJS.fa.g("Error converting legacy ref to pMBQL: %s",x):$CLJS.fa.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.h(null,4,[$CLJS.OF,d,$CLJS.LL,e,kma,f,jma,$CLJS.gZ],null),u);}}finally{$CLJS.fZ=t,$CLJS.gZ=m}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();