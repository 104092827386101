var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var EZ,Kma,Lma,Mma,Nma,Oma,KZ,Pma,Qma,Rma,NZ,Sma,Tma,Uma,SZ,TZ,Vma,Wma,Xma,a_,Yma,Zma,$ma,d_,ana,bna,cna,dna;EZ=function(){};$CLJS.FZ=function(a){return null!=a?$CLJS.Bc===a.ki?!0:a.Wc?!1:$CLJS.$a(EZ,a):$CLJS.$a(EZ,a)};Kma=new $CLJS.r(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.GZ=new $CLJS.M("lib","source-column-alias","lib/source-column-alias",1494821386);
Lma=new $CLJS.M("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);Mma=new $CLJS.M("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.HZ=new $CLJS.M("source","previous-stage","source/previous-stage",1962610878);$CLJS.IZ=new $CLJS.M(null,"database-id","database-id",1883826326);Nma=new $CLJS.r("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.JZ=new $CLJS.M("source","table-defaults","source/table-defaults",909975194);Oma=new $CLJS.M(null,"human-readable-values","human-readable-values",-624842907);KZ=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);Pma=new $CLJS.M("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);Qma=new $CLJS.M(null,"dbms-version","dbms-version",-69238036);
Rma=new $CLJS.M(null,"field-name","field-name",1300687948);$CLJS.LZ=new $CLJS.M("metabase.lib.schema.metadata","column","metabase.lib.schema.metadata/column",-570344650);$CLJS.MZ=new $CLJS.M(null,"dataset","dataset",1159262238);NZ=new $CLJS.M("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);Sma=new $CLJS.M(null,"field-definitions","field-definitions",1650155116);$CLJS.OZ=new $CLJS.M("source","expressions","source/expressions",-458367840);
$CLJS.PZ=new $CLJS.M(null,"definition","definition",-1198729982);Tma=new $CLJS.M(null,"state","state",-1988618099);$CLJS.QZ=new $CLJS.M("source","implicitly-joinable","source/implicitly-joinable",-729101197);Uma=new $CLJS.M(null,"is-audit","is-audit",327058420);$CLJS.RZ=new $CLJS.M("source","joins","source/joins",1225821486);SZ=new $CLJS.M("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);
TZ=new $CLJS.M("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);$CLJS.UZ=new $CLJS.M("lib","desired-column-alias","lib/desired-column-alias",-1998967402);$CLJS.eL=new $CLJS.M(null,"fk-target-field-id","fk-target-field-id",2019750287);Vma=new $CLJS.M("lib","external-remap","lib/external-remap",543358316);$CLJS.VZ=new $CLJS.M(null,"selected?","selected?",-742502788);
Wma=new $CLJS.M("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);Xma=new $CLJS.M("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.WZ=new $CLJS.M(null,"table-name","table-name",-2117866341);$CLJS.XZ=new $CLJS.M("source","card","source/card",-139977973);$CLJS.YZ=new $CLJS.M(null,"active","active",1895962068);$CLJS.ZZ=new $CLJS.M(null,"dataset-query","dataset-query",1851066427);
$CLJS.$Z=new $CLJS.M(null,"result-metadata","result-metadata",2017934672);a_=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);Yma=new $CLJS.M(null,"details","details",1956795411);$CLJS.b_=new $CLJS.M("source","fields","source/fields",-649667981);Zma=new $CLJS.M("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.c_=new $CLJS.M("source","native","source/native",-1444604147);
$ma=new $CLJS.M("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);d_=new $CLJS.M("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);$CLJS.e_=new $CLJS.M("lib","card-id","lib/card-id",-1770167062);ana=new $CLJS.M("metadata.column.remapping","internal","metadata.column.remapping/internal",-1077920983);bna=new $CLJS.r(null,"metadata-provider?","metadata-provider?",1669871524,null);
cna=new $CLJS.M("lib","internal-remap","lib/internal-remap",-220033801);dna=new $CLJS.M("metadata.column.remapping","external","metadata.column.remapping/external",-1813287755);$CLJS.f_=new $CLJS.M(null,"source-alias","source-alias",1652088724);$CLJS.g_=new $CLJS.M(null,"engine","engine",1459054265);$CLJS.X(SZ,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ts,$CLJS.XZ,$CLJS.c_,$CLJS.HZ,$CLJS.JZ,$CLJS.b_,$CLJS.oL,$CLJS.iL,$CLJS.RZ,$CLJS.OZ,$CLJS.QZ],null));$CLJS.X(a_,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.du,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zr,dna],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yi,$CLJS.aL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.BE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bL,$CLJS.mL],null)],null));
$CLJS.X(KZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.du,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zr,ana],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yi,$CLJS.aL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.BE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.it,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,$CLJS.Ns],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[Oma,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,$CLJS.Ns],null)],null)],null));
$CLJS.X($CLJS.LZ,new $CLJS.P(null,19,5,$CLJS.Q,[$CLJS.gk,new $CLJS.h(null,1,[$CLJS.wt,"Valid column metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.du,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zr,$CLJS.TK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.Xj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xB,$CLJS.KE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yi,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),$CLJS.mL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wE,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.vs,$CLJS.Xj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ei,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.KE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fL,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.mL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eL,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.mL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.f_,
new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.BE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AE,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.BE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VK,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SZ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.e_,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.cL],
null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GZ,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.BE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UZ,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xj,new $CLJS.h(null,2,[$CLJS.Xn,1,$CLJS.hk,60],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VZ,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),$CLJS.Ms],null),new $CLJS.P(null,3,5,$CLJS.Q,
[Vma,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,a_],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[cna,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KZ],null)],null)],null)],null));
$CLJS.X(d_,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WZ,$CLJS.BE],null),new $CLJS.P(null,2,5,$CLJS.Q,[Sma,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[Rma,$CLJS.BE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xB,$CLJS.KE],null)],null)],null)],null)],null)],null));
$CLJS.X(NZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YZ,$CLJS.Ms],null),new $CLJS.P(null,2,5,$CLJS.Q,[Tma,$CLJS.BE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WZ,$CLJS.BE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PZ,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,d_],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SK,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,
$CLJS.BE],null)],null)],null));
$CLJS.X(Zma,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.gk,new $CLJS.h(null,1,[$CLJS.wt,"Valid Card metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.du,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zr,$CLJS.nL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yi,$CLJS.cL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.BE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.IZ,$CLJS.dL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZZ,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),$CLJS.gk],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$Z,
new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,$CLJS.gk],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MZ,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),$CLJS.Ms],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gL,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.YK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Mma,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,NZ],null)],null)],null)],null));
$CLJS.X(Wma,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.gk,new $CLJS.h(null,1,[$CLJS.wt,"Valid Segment metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.du,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zr,$CLJS.XK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yi,$CLJS.WK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.BE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gL,$CLJS.YK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.gk],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Qt,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.BE],null)],null)],null));
$CLJS.X(Xma,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.gk,new $CLJS.h(null,1,[$CLJS.wt,"Valid Metric metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.du,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zr,$CLJS.ZK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yi,$CLJS.UK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.BE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gL,$CLJS.YK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.gk],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Qt,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.BE],null)],null)],null));
$CLJS.X($ma,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.gk,new $CLJS.h(null,1,[$CLJS.wt,"Valid Table metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.du,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zr,$CLJS.$K],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yi,$CLJS.YK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.BE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wE,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.BE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ol,new $CLJS.h(null,
1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.BE],null)],null)],null));
$CLJS.X(Lma,new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.gk,new $CLJS.h(null,1,[$CLJS.wt,"Valid Database metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.du,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zr,$CLJS.kL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yi,$CLJS.dL],null),new $CLJS.P(null,3,5,$CLJS.Q,[Qma,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.gk],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Yma,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),$CLJS.gk],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.g_,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),$CLJS.Wi],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lA,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.Wi],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Uma,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),$CLJS.Ms],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hL,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.gk],null)],null)],null));
$CLJS.X(TZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.wt,"Valid MetadataProvider"],null),new $CLJS.Gc(function(){return $CLJS.FZ},Nma,$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.nk,$CLJS.ui,$CLJS.Si,$CLJS.mj,$CLJS.wk,$CLJS.ti,$CLJS.ik,$CLJS.mk],[Kma,bna,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.U(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Xq],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.FZ)?$CLJS.FZ.H:null]))],null));
$CLJS.X(Pma,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TZ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gk,new $CLJS.h(null,1,[$CLJS.wt,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TZ],null)],null)],null)],null));