var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var eX,fla,gX,iX,jX,gla,lX,mX,dX,nX,hla,ila,jla,kla,qX,rX,tX,uX,vX,wX,xX,yX,fX,mla,nla,BX,ola,CX,pla,EX,qla,rla,sla,tla,ula,aX;$CLJS.bX=function(a,b){if("string"===typeof b)return aX(a,b);throw new TypeError("re-seq must match against a string.");};$CLJS.cX=function(a,b,c){var d=$CLJS.dm(a,b);if($CLJS.n(d)){var e=$CLJS.R.j;d=$CLJS.Fb(d);c=c.h?c.h(d):c.call(null,d);a=e.call($CLJS.R,a,b,c)}return a};
eX=function(a){var b=dX;return $CLJS.LA(function(c){return function(d,e,f){e=b.h?b.h(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};fla=function(a){var b=fX;return $CLJS.LA(function(c){return function(d,e,f){f=b.h?b.h(f):b.call(null,f);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};gX=function(a){if($CLJS.md(a))return a;throw $CLJS.hi("Invalid pattern: don't know how to handle symbol.",new $CLJS.h(null,1,[$CLJS.Gi,a],null));};
$CLJS.hX=function(a,b,c){return $CLJS.xd(c)?$CLJS.eg.g(c,function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var z=0;;)if(z<m){var C=$CLJS.kd(l,z),G=$CLJS.I(C,0,null),K=$CLJS.I(C,1,null);C=t;var S=$CLJS.Q,V=G;G=$CLJS.be.g(b,G);G=a.g?a.g(G,K):a.call(null,G,K);C.add(new $CLJS.P(null,2,5,S,[V,G],null));z+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.A(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[v,function(){var z=$CLJS.be.g(b,v),C=x;return a.g?a.g(z,C):a.call(null,z,C)}()],null),f($CLJS.Lc(k)))}return null}},null,null)}(c)}()):$CLJS.wd(c)?$CLJS.Pk.g($CLJS.Ve(a,$CLJS.A(c)instanceof $CLJS.M?$CLJS.be.g(b,$CLJS.A(c)):b),c):c};iX=function(a){var b=(b=a instanceof $CLJS.M)?$CLJS.ie(a):b;return $CLJS.n(b)?[$CLJS.ie(a),"/",$CLJS.gh(a)].join(""):$CLJS.gh(a)};
jX=function(a,b){var c=$CLJS.wd(b)&&!$CLJS.zf(b)&&$CLJS.A(b)instanceof $CLJS.M;return c?$CLJS.ud(a)?(b=$CLJS.A(b),a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.A(b)):c};
gla=function(a,b){return $CLJS.jf(function(c){var d=$CLJS.lf;a:try{if($CLJS.zd(c)&&1<=$CLJS.D(c))try{var e=$CLJS.Uk.j(c,0,1);if($CLJS.zd(e)&&1===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.n($CLJS.Ve($CLJS.E,a)(f))){var k=$CLJS.Uk.g(c,1);var l=new $CLJS.P(null,1,5,$CLJS.Q,[k],null)}else throw $CLJS.Y;}catch(t){if(t instanceof Error){var m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;
}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y){l=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[c],null)],null);break a}throw m;}throw t;}return $CLJS.A($CLJS.y(d($CLJS.Wa,l)))},$CLJS.H([b]))};
lX=function(a,b){for(;;)switch(b=$CLJS.gl.h($CLJS.lf($CLJS.Wa,b)),$CLJS.D(b)){case 0:return null;case 1:return a=$CLJS.A(b),kX.h?kX.h(a):kX.call(null,a);default:if($CLJS.n($CLJS.Re($CLJS.Ve(jX,a),b))){var c=a;b=gla(a,b);a=c}else{c=$CLJS.hf.g(kX,b);if($CLJS.E.g(c,b))return $CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),b);b=c}}};mX=function(a){var b=$CLJS.wd(a);return b?(b=!$CLJS.zf(a))?$CLJS.FA($CLJS.ge,$CLJS.Za)($CLJS.A(a)):b:b};
dX=function(a){return $CLJS.n($CLJS.FA($CLJS.ge,$CLJS.Za)(a))?$CLJS.zh.h($CLJS.JA(iX(a).toLowerCase(),/_/,"-")):a};nX=function(a,b){var c=mX(b);return $CLJS.n(c)?(b=dX($CLJS.A(b)),$CLJS.ud(a)?(a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,b)):c};hla=function(a){return $CLJS.J.j(new $CLJS.h(null,3,[$CLJS.ci,$CLJS.Td,$CLJS.lj,dX,$CLJS.ZN,dX],null),a,function(b){var c=$CLJS.J,d=c.g;b=$CLJS.Ee([a,b]);b=oX.g?oX.g(b,pX):oX.call(null,b,pX);return d.call(c,b,a)})};
ila=function(a){a=$CLJS.eg.j($CLJS.N,$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=dX(c);var d=hla(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,d.h?d.h(b):d.call(null,b)],null)}),a);return $CLJS.E.g($CLJS.lj.h(a),$CLJS.LF)&&$CLJS.Va($CLJS.ZN.h(a))?$CLJS.R.j(a,$CLJS.ZN,$CLJS.KG):a};jla=function(a){return $CLJS.eg.j($CLJS.N,$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=iX(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.R.j(ila(b),$CLJS.T,c)],null)}),a)};
kla=function(a){a=oX.h?oX.h(a):oX.call(null,a);return fX.h?fX.h(a):fX.call(null,a)};qX=function(a){return $CLJS.cX($CLJS.cX($CLJS.fb(function(b,c){return $CLJS.cX(b,c,$CLJS.zh)},a,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.lD,$CLJS.Eea,$CLJS.MO,lla,$CLJS.fA,$CLJS.ri],null)),$CLJS.lC,kla),$CLJS.aO,$CLJS.EV)};rX=function(a){return $CLJS.Fd(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rG,a,null],null):a};tX=function(a){return sX.h(rX(a))};
uX=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);return kX($CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.hf.g(sX,b)))};vX=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,tX(b)],null),$CLJS.hf.g(sX,c))};wX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(a)?new $CLJS.P(null,2,5,$CLJS.Q,[b,tX(a)],null):new $CLJS.P(null,1,5,$CLJS.Q,[b],null)};
xX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,tX(a)],null)};yX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,sX.h(a)],null)};
fX=function(a){return zX(function(b){if($CLJS.xd(b))return fla(b);if($CLJS.Va(mX(b)))return b;try{return sX.h(b)}catch(f){if(f instanceof Error){var c=f,d=$CLJS.PA($CLJS.Iy);if($CLJS.n($CLJS.OA("metabase.mbql.normalize",d))){var e=$CLJS.TE("Invalid clause:");e instanceof Error?$CLJS.NA("metabase.mbql.normalize",d,$CLJS.Gw.l($CLJS.H([b])),e):$CLJS.NA("metabase.mbql.normalize",d,$CLJS.Gw.l($CLJS.H([e,b])),null)}throw $CLJS.gi($CLJS.rE("Invalid MBQL clause: {0}",$CLJS.H([$CLJS.BV(c)])),new $CLJS.h(null,
1,[$CLJS.iS,b],null),c);}throw f;}},a)};mla=function(a){return $CLJS.Df($CLJS.am(fX,AX(a)))};nla=function(a){for(;;)if($CLJS.n(mX(a)))a=new $CLJS.P(null,1,5,$CLJS.Q,[a],null);else return $CLJS.Fe($CLJS.Pk.g(rX,a))};BX=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.LF);return $CLJS.n(b)?$CLJS.Qk.j(a,$CLJS.LF,sX):a};
ola=function(a){return $CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,BX(t)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,
2,5,$CLJS.Q,[l,BX(f)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};CX=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.PQ);return $CLJS.n(b)?$CLJS.Qk.j(a,$CLJS.PQ,ola):a};pla=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.sQ);b=$CLJS.Va(a)?DX.h?DX.h(b):DX.call(null,b):b;return $CLJS.n(a)?CX(b):b};EX=function(a){return $CLJS.ud(a)?$CLJS.y(a):null!=a};
qla=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.OF);var c=$CLJS.J.g(b,$CLJS.DN),d=$CLJS.J.g(b,$CLJS.$O),e=$CLJS.J.g(b,$CLJS.sQ);try{if($CLJS.n(d)){var f=$CLJS.O(b),k=$CLJS.J.g(f,$CLJS.$O);var l=$CLJS.eM($CLJS.Fk.g(f,$CLJS.$O),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OF,$CLJS.$O],null),k)}else l=b;var m=$CLJS.n(a)?$CLJS.Qk.j(l,$CLJS.OF,DX):l,t=$CLJS.n(c)?$CLJS.Qk.j(m,$CLJS.DN,$CLJS.Ve($CLJS.Pk,fX)):m,u=$CLJS.n(e)?$CLJS.Qk.j(t,$CLJS.sQ,CX):t;return fX(u)}catch(v){if(v instanceof Error)throw m=v,$CLJS.gi($CLJS.rE("Error canonicalizing query: {0}",
$CLJS.H([$CLJS.BV(m)])),new $CLJS.h(null,1,[$CLJS.OF,a],null),m);throw v;}};
rla=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.OF),c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.fG);c=$CLJS.J.g(c,$CLJS.aF);return $CLJS.y(b)&&$CLJS.y(c)?(b=$CLJS.eg.j($CLJS.bh,$CLJS.xC,$CLJS.y($CLJS.lf($CLJS.Wa,function k(e,f){try{if($CLJS.zd(f)&&3===$CLJS.D(f))try{var l=$CLJS.F(f,0);if($CLJS.he(l,$CLJS.rG)){var m=$CLJS.F(f,1),t=$CLJS.F(f,2);return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[f,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rG,m,$CLJS.Fk.g(t,$CLJS.xG)],null)],null)],null)}throw $CLJS.Y;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw u;}else throw $CLJS.Y;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Y)return $CLJS.JV(k,e,f);throw l;}throw u;}}($CLJS.Cf,b)))),$CLJS.FX.j(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OF,$CLJS.aF],null),$CLJS.Nk.g($CLJS.Df,$CLJS.Ve($CLJS.Ok,b)))):a};
sla=function(a){try{return rla(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.gi($CLJS.TE("Error performing whole query transformations"),new $CLJS.h(null,1,[$CLJS.OF,a],null),b);}throw c;}};
tla=function(a,b){var c=$CLJS.eg.g($CLJS.jd(a),function(){return function f(e){return new $CLJS.ne(null,function(){for(var k=e;;)if(k=$CLJS.y(k)){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var z=0;;)if(z<m){var C=$CLJS.kd(l,z),G=$CLJS.I(C,0,null);C=$CLJS.I(C,1,null);var K=$CLJS.be.g(b,G);C=GX.g?GX.g(C,K):GX.call(null,C,K);null!=C&&t.add(new $CLJS.P(null,2,5,$CLJS.Q,[G,C],null));z+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.A(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);u=function(){var z=x,C=$CLJS.be.g(b,v);return GX.g?GX.g(z,C):GX.call(null,z,C)}();if(null!=u)return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null),f($CLJS.Lc(k)));k=$CLJS.Lc(k)}else return null},null,null)}(a)}());return $CLJS.y(c)?c:null};ula=function(a,b){a=$CLJS.Pk.g(function(c){var d=$CLJS.be.g(b,HX);return GX.g?GX.g(c,d):GX.call(null,c,d)},a);return $CLJS.n($CLJS.Re($CLJS.Wa,a))?a:null};
aX=function aX(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.Df(d);return $CLJS.ee(f,new $CLJS.ne(null,function(){var k=e.length;k=d.index+(1>k?1:k);return k<=b.length?(k=b.substring(k),aX.g?aX.g(a,k):aX.call(null,a,k)):null},null,null))};
$CLJS.FX=function FX(a){switch(arguments.length){case 3:return FX.j(arguments[0],arguments[1],arguments[2]);case 4:return FX.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return FX.N(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return FX.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return FX.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.w(c.slice(6),0,null))}};$CLJS.FX.j=function(a,b,c){var d=$CLJS.y(b);b=$CLJS.A(d);if(d=$CLJS.B(d))a=$CLJS.R.j(a,b,$CLJS.FX.j($CLJS.J.g(a,b),d,c));else{d=$CLJS.R.j;var e=$CLJS.J.g(a,b);c=c.h?c.h(e):c.call(null,e);a=d.call($CLJS.R,a,b,c)}return a};
$CLJS.FX.v=function(a,b,c,d){var e=$CLJS.y(b);b=$CLJS.A(e);if(e=$CLJS.B(e))a=$CLJS.R.j(a,b,$CLJS.FX.v($CLJS.J.g(a,b),e,c,d));else{e=$CLJS.R.j;var f=$CLJS.J.g(a,b);c=c.g?c.g(f,d):c.call(null,f,d);a=e.call($CLJS.R,a,b,c)}return a};$CLJS.FX.N=function(a,b,c,d,e){var f=$CLJS.y(b);b=$CLJS.A(f);if(f=$CLJS.B(f))a=$CLJS.R.j(a,b,$CLJS.FX.N($CLJS.J.g(a,b),f,c,d,e));else{f=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=c.j?c.j(k,d,e):c.call(null,k,d,e);a=f.call($CLJS.R,a,b,c)}return a};
$CLJS.FX.W=function(a,b,c,d,e,f){var k=$CLJS.y(b);b=$CLJS.A(k);if(k=$CLJS.B(k))a=$CLJS.R.j(a,b,$CLJS.FX.W($CLJS.J.g(a,b),k,c,d,e,f));else{k=$CLJS.R.j;var l=$CLJS.J.g(a,b);c=c.v?c.v(l,d,e,f):c.call(null,l,d,e,f);a=k.call($CLJS.R,a,b,c)}return a};$CLJS.FX.l=function(a,b,c,d,e,f,k){var l=$CLJS.y(b);b=$CLJS.A(l);return(l=$CLJS.B(l))?$CLJS.R.j(a,b,$CLJS.Se.l($CLJS.FX,$CLJS.J.g(a,b),l,c,d,$CLJS.H([e,f,k]))):$CLJS.R.j(a,b,$CLJS.Se.l(c,$CLJS.J.g(a,b),d,e,f,$CLJS.H([k])))};
$CLJS.FX.B=function(a){var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);var e=$CLJS.B(d);d=$CLJS.A(e);var f=$CLJS.B(e);e=$CLJS.A(f);var k=$CLJS.B(f);f=$CLJS.A(k);k=$CLJS.B(k);return this.l(b,a,c,d,e,f,k)};$CLJS.FX.A=6;
var zX=function zX(a,b){return $CLJS.CV($CLJS.Ve(zX,a),$CLJS.Td,a.h?a.h(b):a.call(null,b))},kX=function kX(a){for(;;){if($CLJS.xd(a))return $CLJS.Gt(a,kX);if($CLJS.Dd(a))a=$CLJS.Df(a);else if($CLJS.zd(a)){if($CLJS.Va($CLJS.Re($CLJS.Wa,a)))return null;var c=a,d=$CLJS.y(c),e=$CLJS.A(d),f=$CLJS.B(d),k=e,l=f,m=$CLJS.I(l,0,null),t=l,u=k,v=u instanceof $CLJS.M?u.T:null;switch(v){case "not":if($CLJS.Kl(m)){var x=$CLJS.A(m),z=x instanceof $CLJS.M?x.T:null;switch(z){case "not":a=$CLJS.hd(m);continue;case "and":return lX($CLJS.ws,
$CLJS.hf.g(function(){return function(C){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,C],null)}}(a,x,z,u,v,c,d,e,f,k,l,m,t),$CLJS.Lc(m)));case "or":return lX($CLJS.Ds,$CLJS.hf.g(function(){return function(C){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,C],null)}}(a,x,z,u,v,c,d,e,f,k,l,m,t),$CLJS.Lc(m)));default:return a}}else return a;case "and":return lX($CLJS.Ds,t);case "or":return lX($CLJS.ws,t);default:return $CLJS.Pk.g(kX,a)}}else return a}},vla=new $CLJS.M(null,"value_field","value_field",
-980977878),IX=new $CLJS.M(null,"ascending","ascending",-988350486),JX=new $CLJS.M(null,"named","named",-422393479),KX=new $CLJS.M(null,"values_source_config","values_source_config",-590570309),LX=new $CLJS.M(null,"descending","descending",-24766135),MX=new $CLJS.M(null,"datetime-field","datetime-field",21731696),wla=new $CLJS.M(null,"use-as-display-name?","use-as-display-name?",686752941),NX=new $CLJS.M(null,"field-literal","field-literal",-1295883554),OX=new $CLJS.M(null,"viz-settings","viz-settings",
256055379),xla=new $CLJS.M(null,"rows","rows",850049680),yla=new $CLJS.M(null,"special-fn","special-fn",1290649344),HX=new $CLJS.M("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),pX=new $CLJS.M(null,"ignore-path","ignore-path",944069061),zla=new $CLJS.M(null,"label_field","label_field",-1573182765),Ala=new $CLJS.M(null,"fk-\x3e","fk-\x3e",-499026738),Bla=new $CLJS.M(null,"joined-field","joined-field",-2048778268),lla=new $CLJS.M(null,"visibility_type","visibility_type",
-508434247);var PX,Cla=$CLJS.Xe($CLJS.N),Dla=$CLJS.Xe($CLJS.N),Ela=$CLJS.Xe($CLJS.N),Fla=$CLJS.Xe($CLJS.N),Gla=$CLJS.J.j($CLJS.N,$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));PX=new $CLJS.di($CLJS.Fh.g("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.Nk.g(dX,$CLJS.A),Gla,Cla,Dla,Ela,Fla);PX.m(null,$CLJS.gG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gG,b],null);return null!=a?$CLJS.be.g(b,a):b});
PX.m(null,$CLJS.uB,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uB,a instanceof $CLJS.M?iX(a):a],null)});PX.m(null,$CLJS.LV,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?$CLJS.be.g(PX.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LV,b,c],null)),a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LV,oX.g?oX.g(b,pX):oX.call(null,b,pX),dX(c)],null)});
PX.m(null,$CLJS.rG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=oX.g?oX.g(a,pX):oX.call(null,a,pX);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rG,b,function(){var d=$CLJS.n($CLJS.xB.h(c))?$CLJS.Qk.j(c,$CLJS.xB,$CLJS.zh):c;d=$CLJS.n($CLJS.xG.h(c))?$CLJS.Qk.j(d,$CLJS.xG,$CLJS.zh):d;return $CLJS.n($CLJS.DR.h(c))?$CLJS.Qk.j(d,$CLJS.DR,function(e){return $CLJS.n($CLJS.ME.h(e))?$CLJS.Qk.j(e,$CLJS.ME,$CLJS.zh):e}):d}()],null)});
PX.m(null,NX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[NX,b instanceof $CLJS.M?iX(b):b,$CLJS.zh.h(a)],null)});PX.m(null,MX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[MX,oX.g?oX.g(b,pX):oX.call(null,b,pX),$CLJS.nt,dX(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[MX,oX.g?oX.g(b,pX):oX.call(null,b,pX),dX(c)],null)});
PX.m(null,$CLJS.DG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);return $CLJS.n(a)?$CLJS.be.g(PX.h(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.DG,b,c,d],null)),oX.g?oX.g(a,pX):oX.call(null,a,pX)):new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.DG,oX.g?oX.g(b,pX):oX.call(null,b,pX),$CLJS.Fd(c)?c:dX(c),dX(d)],null)});
PX.m(null,$CLJS.gJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gJ,b,dX(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gJ,$CLJS.Zv],null)});PX.m(null,$CLJS.eJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eJ,b,dX(a)],null)});
PX.m(null,$CLJS.oJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.oJ,oX.g?oX.g(b,pX):oX.call(null,b,pX),c,dX(a)],null)});PX.m(null,$CLJS.uJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.uJ,oX.g?oX.g(b,pX):oX.call(null,b,pX),c,dX(a)],null)});
PX.m(null,$CLJS.mJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mJ,oX.g?oX.g(b,pX):oX.call(null,b,pX),dX(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mJ,oX.g?oX.g(b,pX):oX.call(null,b,pX)],null)});
PX.m(null,$CLJS.sJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.sJ,oX.g?oX.g(b,pX):oX.call(null,b,pX),dX(c),dX(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sJ,oX.g?oX.g(b,pX):oX.call(null,b,pX),dX(c)],null)});
PX.m(null,$CLJS.dJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dJ,oX.g?oX.g(b,pX):oX.call(null,b,pX),oX.g?oX.g(c,pX):oX.call(null,c,pX),dX(a)],null)});PX.m(null,$CLJS.Cj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cj,b,a],null)});
PX.m(null,$CLJS.ci,function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);return $CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[dX(a)],null),$CLJS.hf.h(function(c){return oX.g?oX.g(c,pX):oX.call(null,c,pX)}),b)});
var QX=function QX(a){if($CLJS.n($CLJS.FA($CLJS.ge,$CLJS.Za)(a))){var c=dX(a);var d=new $CLJS.ah(null,new $CLJS.h(null,18,[$CLJS.Xn,null,$CLJS.bs,null,$CLJS.GG,null,$CLJS.AG,null,$CLJS.pt,null,$CLJS.sG,null,$CLJS.LG,null,$CLJS.MG,null,$CLJS.XF,null,$CLJS.cG,null,$CLJS.as,null,$CLJS.YF,null,$CLJS.FG,null,$CLJS.yG,null,$CLJS.hk,null,$CLJS.Xx,null,$CLJS.lG,null,$CLJS.EG,null],null),null);c=d.h?d.h(c):d.call(null,c)}else c=null;return $CLJS.n(c)?c:$CLJS.n(mX(a))?(a=$CLJS.A(a),QX.h?QX.h(a):QX.call(null,
a)):null},Hla=new $CLJS.h(null,8,[$CLJS.lj,dX,$CLJS.sQ,function(a){a=eX(a);return $CLJS.y($CLJS.PQ.h(a))?$CLJS.Qk.j(a,$CLJS.PQ,jla):a},$CLJS.OF,new $CLJS.h(null,6,[$CLJS.gG,function RX(a){if($CLJS.n($CLJS.FA($CLJS.ge,$CLJS.Za)(a)))return dX(a);if($CLJS.n(nX(JX,a))){a=$CLJS.y(a);$CLJS.A(a);var c=$CLJS.B(a);a=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[JX,RX.h?RX.h(a):RX.call(null,a)],null),c)}return $CLJS.n($CLJS.n(mX(a))?QX($CLJS.hd(a)):null)?$CLJS.Pk.g(RX,a):oX.g?oX.g(a,
pX):oX.call(null,a,pX)},$CLJS.hQ,function(a){return $CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[iX(u),oX.g?oX.g(t,pX):oX.call(null,t,pX)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=
$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[iX(l),oX.g?oX.g(f,pX):oX.call(null,f,pX)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.YR,function(a){return $CLJS.Df(function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m);t=$CLJS.n(mX(t))?PX.h(t):$CLJS.ce(PX.h($CLJS.ce(t)));l.add(t);m+=
1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}l=$CLJS.A(e);return $CLJS.ee($CLJS.n(mX(l))?PX.h(l):$CLJS.ce(PX.h($CLJS.ce(l))),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.RQ,function(a){a=eX(a);a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.sQ);if($CLJS.n(b))return a=$CLJS.IV(a,new $CLJS.h(null,1,[$CLJS.sQ,$CLJS.OF],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.sQ],null),a=oX.g?oX.g(a,b):oX.call(null,a,b),$CLJS.IV(a,new $CLJS.h(null,1,[$CLJS.OF,$CLJS.sQ],
null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.OF],null);return oX.g?oX.g(a,b):oX.call(null,a,b)},$CLJS.$O,new $CLJS.h(null,1,[HX,qX],null),$CLJS.MN,new $CLJS.h(null,1,[HX,function(a){a=oX.g?oX.g(a,$CLJS.OF):oX.call(null,a,$CLJS.OF);var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.ME),d=$CLJS.J.g(b,$CLJS.aF);a=$CLJS.J.g(b,$CLJS.eF);b=$CLJS.n(c)?$CLJS.Qk.j(b,$CLJS.ME,dX):b;d=$CLJS.n($CLJS.FA($CLJS.ge,$CLJS.Za)(d))?$CLJS.Qk.j(b,$CLJS.aF,dX):b;return $CLJS.n(a)?$CLJS.Qk.j(d,$CLJS.eF,iX):d}],null)],null),$CLJS.My,
new $CLJS.h(null,1,[$CLJS.xO,$CLJS.Td],null),$CLJS.DN,new $CLJS.h(null,1,[HX,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.lj),d=$CLJS.J.g(b,$CLJS.zJ),e=$CLJS.J.g(b,$CLJS.Yi);a=$CLJS.J.g(b,KX);b=$CLJS.n(e)?$CLJS.Qk.j(b,$CLJS.Yi,iX):b;c=$CLJS.n(c)?$CLJS.Qk.j(b,$CLJS.lj,dX):b;d=$CLJS.n(d)?$CLJS.Qk.j(c,$CLJS.zJ,function(f){return oX.g?oX.g(f,pX):oX.call(null,f,pX)}):c;d=$CLJS.n(a)?$CLJS.FX.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[KX,zla],null),function(f){return oX.g?oX.g(f,pX):oX.call(null,f,pX)}):d;return $CLJS.n(a)?
$CLJS.FX.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[KX,vla],null),function(f){return oX.g?oX.g(f,pX):oX.call(null,f,pX)}):d}],null),$CLJS.yN,function(a){return null==a?null:dX(a)},$CLJS.$O,new $CLJS.h(null,1,[HX,qX],null),OX,dX],null),oX=function oX(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return oX.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
oX.l=function(a,b){b=$CLJS.I(b,0,null);var c=b instanceof $CLJS.M?new $CLJS.P(null,1,5,$CLJS.Q,[b],null):$CLJS.Df(b);b=$CLJS.y(c)?$CLJS.GA(Hla,c):null;try{return $CLJS.md(b)?b.h?b.h(a):b.call(null,a):$CLJS.yd(a)?a:$CLJS.xd(a)?$CLJS.eg.g($CLJS.N,function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),z=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);z=dX(z);z=new $CLJS.P(null,
2,5,$CLJS.Q,[z,oX.l(x,$CLJS.H([$CLJS.be.g($CLJS.Df(c),z)]))],null);u.add(z);v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}m=$CLJS.A(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);u=dX(u);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[u,oX.l(m,$CLJS.H([$CLJS.be.g($CLJS.Df(c),u)]))],null),k($CLJS.Lc(l)))}return null}},null,null)}(a)}()):$CLJS.n(mX(a))?PX.h(a):$CLJS.wd(a)?$CLJS.Pk.g(function(e){return oX.l(e,$CLJS.H([$CLJS.be.g($CLJS.Df(c),HX)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.gi($CLJS.rE("Error normalizing form: {0}",$CLJS.H([$CLJS.BV(d)])),new $CLJS.h(null,3,[$CLJS.dA,a,$CLJS.nl,c,yla,b],null),d);}throw e;}};oX.A=1;oX.B=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};
var sX=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.n(mX(f))?$CLJS.A(f):null},e,a,b,c,d)}();sX.m(null,$CLJS.ci,function(a){return a});
sX.m(null,$CLJS.rG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(nX($CLJS.rG,b))){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);return sX.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rG,c,$CLJS.Fe($CLJS.el.l($CLJS.H([b,a])))],null))}return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rG,b,$CLJS.Fe(a)],null)});
sX.m(null,$CLJS.gG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.td(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gG,b],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gG,b,a],null)});sX.m(null,$CLJS.bL,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(mX(a))?sX.h(a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rG,a,null],null)});
sX.m(null,NX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rG,b,new $CLJS.h(null,1,[$CLJS.xB,a],null)],null)});sX.m(null,Ala,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=tX(b);$CLJS.I(c,0,null);b=$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=tX(a);$CLJS.I(c,0,null);a=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rG,a,$CLJS.R.j(c,$CLJS.NN,b)],null)});
sX.m(null,Bla,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=tX(a);return $CLJS.Se.v($CLJS.QV,a,$CLJS.R,$CLJS.H([$CLJS.CP,b]))});
sX.m(null,MX,function(a){var b=$CLJS.D(a);switch(b){case 3:$CLJS.I(a,0,null);b=$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null);a=tX(b);b=c;$CLJS.I(a,0,null);$CLJS.I(a,1,null);c=$CLJS.I(a,2,null);c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.xB);$CLJS.Va(d)||$CLJS.US.g(d,b)?a=$CLJS.Se.v($CLJS.QV,a,$CLJS.R,$CLJS.H([$CLJS.xG,b])):(c=$CLJS.PA($CLJS.Ky),$CLJS.n($CLJS.OA("metabase.mbql.util",c))&&(b=$CLJS.rE("{0} is not a valid temporal unit for {1}; not adding to clause {2}",$CLJS.H([b,d,$CLJS.Mh.l($CLJS.H([a]))])),
b instanceof Error?$CLJS.NA("metabase.mbql.util",c,$CLJS.Gw(),b):$CLJS.NA("metabase.mbql.util",c,$CLJS.Gw.l($CLJS.H([b])),null)));return a;case 4:return $CLJS.I(a,0,null),b=$CLJS.I(a,1,null),$CLJS.I(a,2,null),c=$CLJS.I(a,3,null),sX.h(new $CLJS.P(null,3,5,$CLJS.Q,[MX,b,c],null));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}});
sX.m(null,$CLJS.LV,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);var e=tX(b);$CLJS.I(e,0,null);b=$CLJS.I(e,1,null);e=$CLJS.I(e,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rG,b,$CLJS.R.j(e,$CLJS.DR,$CLJS.el.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.ME,c],null),$CLJS.n(d)?$CLJS.Ee([c,d]):null,a])))],null)});
for(var SX=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ds,$CLJS.ws,$CLJS.ys],null)),TX=null,UX=0,VX=0;;)if(VX<UX){var WX=TX.X(null,VX);sX.m(null,WX,function(){return function(a){return uX(a)}}(SX,TX,UX,VX,WX));VX+=1}else{var XX=$CLJS.y(SX);if(XX){var YX=XX;if($CLJS.Ad(YX)){var ZX=$CLJS.lc(YX),Ila=$CLJS.mc(YX),Jla=ZX,Kla=$CLJS.D(ZX);SX=Ila;TX=Jla;UX=Kla}else{var $X=$CLJS.A(YX);sX.m(null,$X,function(){return function(a){return uX(a)}}(SX,TX,UX,VX,$X,YX,XX));SX=$CLJS.B(YX);TX=null;UX=0}VX=0}else break}
sX.m(null,$CLJS.$F,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$F,tX(a),tX(b)],null),c)});sX.m(null,$CLJS.DG,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=tX(a);a=$CLJS.n(jX($CLJS.rG,a))?$CLJS.QV.l(c,$CLJS.Fk,$CLJS.H([$CLJS.xG])):c;return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DG,a],null),b)});
for(var aY=$CLJS.y(new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.iG,$CLJS.CG,$CLJS.qG,$CLJS.jG,$CLJS.Zr,$CLJS.IG,$CLJS.Wr,$CLJS.Yr,$CLJS.Sr,$CLJS.Ur,$CLJS.VF,$CLJS.bG,$CLJS.TF,$CLJS.dG,$CLJS.UF],null)),bY=null,cY=0,dY=0;;)if(dY<cY){var eY=bY.X(null,dY);sX.m(null,eY,function(){return function(a){return vX(a)}}(aY,bY,cY,dY,eY));dY+=1}else{var fY=$CLJS.y(aY);if(fY){var gY=fY;if($CLJS.Ad(gY)){var hY=$CLJS.lc(gY),Lla=$CLJS.mc(gY),Mla=hY,Nla=$CLJS.D(hY);aY=Lla;bY=Mla;cY=Nla}else{var iY=$CLJS.A(gY);sX.m(null,iY,
function(){return function(a){return vX(a)}}(aY,bY,cY,dY,iY,gY,fY));aY=$CLJS.B(gY);bY=null;cY=0}dY=0}else break}sX.m(null,xla,function(){return null});sX.m(null,$CLJS.DM,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DM,sX.h(b),a],null)});
sX.m(null,JX,function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=sX.h;var e=$CLJS.Q;b=sX.h(b);d=$CLJS.I(d,0,null);d=$CLJS.O(d);a=!1===$CLJS.J.g(d,wla)?new $CLJS.h(null,1,[$CLJS.T,a],null):new $CLJS.h(null,1,[$CLJS.wE,a],null);return c.call(sX,new $CLJS.P(null,3,5,e,[$CLJS.DM,b,a],null))});
for(var jY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xx,$CLJS.sG],null)),kY=null,lY=0,mY=0;;)if(mY<lY){var nY=kY.X(null,mY);sX.m(null,nY,function(){return function(a){return wX(a)}}(jY,kY,lY,mY,nY));mY+=1}else{var oY=$CLJS.y(jY);if(oY){var pY=oY;if($CLJS.Ad(pY)){var qY=$CLJS.lc(pY),Ola=$CLJS.mc(pY),Pla=qY,Qla=$CLJS.D(qY);jY=Ola;kY=Pla;lY=Qla}else{var rY=$CLJS.A(pY);sX.m(null,rY,function(){return function(a){return wX(a)}}(jY,kY,lY,mY,rY,pY,oY));jY=$CLJS.B(pY);kY=null;lY=0}mY=0}else break}
for(var sY=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.lG,$CLJS.NG,$CLJS.XF,$CLJS.GG,$CLJS.EG,$CLJS.Xn,$CLJS.hk,$CLJS.FG,$CLJS.YF],null)),tY=null,uY=0,vY=0;;)if(vY<uY){var wY=tY.X(null,vY);sX.m(null,wY,function(){return function(a){return xX(a)}}(sY,tY,uY,vY,wY));vY+=1}else{var xY=$CLJS.y(sY);if(xY){var yY=xY;if($CLJS.Ad(yY)){var zY=$CLJS.lc(yY),Rla=$CLJS.mc(yY),Sla=zY,Tla=$CLJS.D(zY);sY=Rla;tY=Sla;uY=Tla}else{var AY=$CLJS.A(yY);sX.m(null,AY,function(){return function(a){return xX(a)}}(sY,tY,uY,vY,
AY,yY,xY));sY=$CLJS.B(yY);tY=null;uY=0}vY=0}else break}sX.m(null,$CLJS.cG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cG,tX(b),a],null)});
for(var BY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yG,$CLJS.AG],null)),CY=null,DY=0,EY=0;;)if(EY<DY){var FY=CY.X(null,EY);sX.m(null,FY,function(){return function(a){return yX(a)}}(BY,CY,DY,EY,FY));EY+=1}else{var GY=$CLJS.y(BY);if(GY){var HY=GY;if($CLJS.Ad(HY)){var IY=$CLJS.lc(HY),Ula=$CLJS.mc(HY),Vla=IY,Wla=$CLJS.D(IY);BY=Ula;CY=Vla;DY=Wla}else{var JY=$CLJS.A(HY);sX.m(null,JY,function(){return function(a){return yX(a)}}(BY,CY,DY,EY,JY,HY,GY));BY=$CLJS.B(HY);CY=null;DY=0}EY=0}else break}
sX.m(null,$CLJS.LG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LG,sX.h(b),sX.h(a)],null)});
sX.m(null,$CLJS.aG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?$CLJS.be.g(sX.h(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aG,b],null)),oX.l(a,$CLJS.H([pX]))):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aG,$CLJS.Df(function(){return function e(d){return new $CLJS.ne(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,
2,5,$CLJS.Q,[sX.h(v),sX.h(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}k=$CLJS.A(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[sX.h(m),sX.h(k)],null),e($CLJS.Lc(f)))}return null}},null,null)}(b)}())],null)});
sX.m(null,$CLJS.KF,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KF,sX.h(a),$CLJS.E.g(0,b)?1:sX.h(b)],null),$CLJS.hf.g(sX,c))});
var AX=function AX(a){return function f(d,e){try{if($CLJS.n(function(){var x=gX($CLJS.Dd);return x.h?x.h(e):x.call(null,e)}()))return f(d,$CLJS.Df(e));throw $CLJS.Y;}catch(x){if(x instanceof Error)if(d=x,d===$CLJS.Y)try{if($CLJS.n(function(){var z=gX($CLJS.ge);return z.h?z.h(e):z.call(null,e)}()))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[e],null)],null);throw $CLJS.Y;}catch(z){if(z instanceof Error)if(d=z,d===$CLJS.Y)try{if($CLJS.zd(e)&&2<=$CLJS.D(e))try{var k=$CLJS.Uk.j(e,
0,2);if($CLJS.zd(k)&&2===$CLJS.D(k))try{var l=$CLJS.F(k,0);if($CLJS.n($CLJS.pE($CLJS.ge,$CLJS.Te(new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.bs,null,$CLJS.pt,null,$CLJS.MG,null,$CLJS.as,null,JX,null],null),null)))(l)))try{var m=$CLJS.F(k,1);if($CLJS.n(QX(m)))return $CLJS.eg.j($CLJS.Cf,$CLJS.qE(AX),a);throw $CLJS.Y;}catch(C){if(C instanceof Error){var t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;
}else throw $CLJS.Y;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error)if(t=C,t===$CLJS.Y)try{if($CLJS.zd(e)&&1<=$CLJS.D(e))try{var u=$CLJS.Uk.j(e,0,1);if($CLJS.zd(u)&&1===$CLJS.D(u))try{if($CLJS.F(u,0)instanceof $CLJS.M)return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Y;}catch(G){if(G instanceof Error){var v=G;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){v=
G;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Y)return e;throw v;}throw G;}else throw t;else throw C;}else throw d;else throw z;}else throw d;else throw x;}}($CLJS.Cf,a)},KY=function KY(a){return function f(d,e){try{var k=gX($CLJS.Dd);var l=k.h?k.h(e):k.call(null,e);if($CLJS.n(l))return f(d,$CLJS.Df(e));throw $CLJS.Y;}catch(C){if(C instanceof Error)if(l=C,l===$CLJS.Y)try{if($CLJS.zd(e)&&2===$CLJS.D(e))try{var m=$CLJS.F(e,1);if($CLJS.he(m,
$CLJS.oG)){var t=$CLJS.F(e,0);return f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oG,t],null))}throw $CLJS.Y;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,$CLJS.hG))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hG,t],null));throw $CLJS.Y;}catch(K){if(K instanceof Error){var u=K;if(u===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,IX))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oG,t],null));throw $CLJS.Y;}catch(S){if(S instanceof Error)if(l=
S,l===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,LX))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hG,t],null));throw $CLJS.Y;}catch(V){if(V instanceof Error)if(m=V,m===$CLJS.Y)try{var v=$CLJS.F(e,0);if($CLJS.he(v,IX))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oG,t],null));throw $CLJS.Y;}catch(Z){if(Z instanceof Error)if(m=Z,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,LX))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hG,t],null));throw $CLJS.Y;}catch(ha){if(ha instanceof
Error)if(m=ha,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,$CLJS.oG))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oG,tX(t)],null);throw $CLJS.Y;}catch(ra){if(ra instanceof Error)if(m=ra,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,$CLJS.hG))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hG,tX(t)],null);throw $CLJS.Y;}catch(Na){if(Na instanceof Error){t=Na;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw Na;}else throw m;else throw ra;}else throw m;else throw ha;}else throw m;else throw Z;
}else throw m;else throw V;}else throw l;else throw S;}else throw u;}else throw K;}else throw l;else throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Y)try{if($CLJS.zd(e)&&0<=$CLJS.D(e))try{var x=$CLJS.Uk.j(e,0,0);if($CLJS.zd(x)&&0===$CLJS.D(x))try{var z=$CLJS.Uk.g(e,0);if($CLJS.E.g(z,a))return $CLJS.Df($CLJS.gl.h($CLJS.hf.g(KY,a)));throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof
Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)return $CLJS.hX(f,d,e);throw u;}throw K;}else throw l;else throw G;}else throw l;else throw C;}}($CLJS.Cf,a)},DX=$CLJS.Nk.g(fX,function(a){var b=EX($CLJS.gG.h(a))?$CLJS.Qk.j(a,$CLJS.gG,mla):a;b=EX($CLJS.fG.h(a))?$CLJS.Qk.j(b,$CLJS.fG,nla):b;b=EX($CLJS.aF.h(a))?$CLJS.Qk.j(b,$CLJS.aF,$CLJS.Ve($CLJS.Pk,rX)):b;b=EX($CLJS.YR.h(a))?$CLJS.Qk.j(b,$CLJS.YR,KY):b;return EX($CLJS.RQ.h(a))?
$CLJS.Qk.j(b,$CLJS.RQ,pla):b}),Xla=new $CLJS.h(null,3,[$CLJS.sQ,$CLJS.Td,$CLJS.OF,new $CLJS.h(null,2,[$CLJS.RQ,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.sQ);if($CLJS.n(b))return a=$CLJS.IV(a,new $CLJS.h(null,1,[$CLJS.sQ,$CLJS.OF],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.sQ],null),a=GX.g?GX.g(a,b):GX.call(null,a,b),$CLJS.IV(a,new $CLJS.h(null,1,[$CLJS.OF,$CLJS.sQ],null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.OF],null);return GX.g?GX.g(a,b):GX.call(null,a,b)},$CLJS.MN,new $CLJS.h(null,1,
[HX,function(a){var b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.OF],null);return GX.g?GX.g(a,b):GX.call(null,a,b)}],null)],null),OX,$CLJS.Td],null),GX=function GX(a){switch(arguments.length){case 1:return GX.h(arguments[0]);case 2:return GX.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};GX.h=function(a){return GX.g(a,$CLJS.Cf)};
GX.g=function(a,b){try{var c=$CLJS.y(b)?$CLJS.GA(Xla,b):null;return $CLJS.md(c)?c.h?c.h(a):c.call(null,a):$CLJS.yd(a)?a:$CLJS.xd(a)?tla(a,b):$CLJS.wd(a)?ula(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.gi("Error removing empty clauses from form.",new $CLJS.h(null,2,[$CLJS.dA,a,$CLJS.nl,b],null),d);throw d;}};GX.A=2;
$CLJS.LY=function(){var a=$CLJS.Nk.l(GX,sla,qla,$CLJS.H([oX]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.gi($CLJS.rE("Error normalizing query: {0}",$CLJS.H([$CLJS.BV(c)])),new $CLJS.h(null,1,[$CLJS.OF,b],null),c);}throw d;}}}();$CLJS.MY=function MY(a,b){if($CLJS.Va($CLJS.y(a)))a=(0,$CLJS.LY)(b);else{var d=$CLJS.J,e=d.g;var f=$CLJS.Et(a);b=$CLJS.Ee([$CLJS.id(a),b]);f=MY.g?MY.g(f,b):MY.call(null,f,b);a=e.call(d,f,$CLJS.id(a))}return a};